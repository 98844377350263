import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import ArrowDown from '../../assets/images/svgs/ArrowD.svg'; // Make sure you have this asset
import ArrowUp from '../../assets/images/svgs/ArrowUp.svg';
import SendReceiveBlock from './SendReceiveBlock';
import Text from './Text';

interface AmountTabProps {
  title: string;
  amount: string;
  percentage: string;
  isPositive: boolean;
  showReceiveBlock?: boolean;
}

const AmountTab = ({ title, amount, percentage, isPositive, showReceiveBlock }: AmountTabProps) => {
  return (
    <Box
      display="flex"
      // minHeight="184px"
      padding="24px"
      alignItems="flex-start"
      height={['fit-content', 'fit-content', '226px', '226px', '191.2px']}
      gap="16px"
      flex="1 0 0"
      width={'100%'}
      justifyContent={'space-between'}
      flexDirection={'column'}
      borderRadius="36px"
      border={[
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(255, 255, 255, 0.10)',
      ]}
      background="var(--Neutrals-1, #FFF)"
      boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap="12px" alignSelf="stretch">
        <Text fs="16px" lineHeight="28px">
          {title}
        </Text>
        <Text fs="42px" lineHeight="52px" letterSpacing={'-1.26px'} c="var(--Dark-Tertiary-tr20, #080808)">
          {amount}
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        flexDirection={['row', 'row', 'row', 'column', 'row']}
        flexWrap="wrap"
        width={'100%'}
      >
        <Box display="flex" alignItems="center" gap="8px">
          <Box display="flex" alignItems="center" justifyContent={'center'}>
            <Image src={isPositive ? ArrowUp : ArrowDown} />
            <Text fs="14px" fw={500} c={isPositive ? 'var(--green-5, #39CA7F)' : 'var(--red-5, #FF4D4F)'}>
              {percentage}
            </Text>
          </Box>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500}>
            vs last 24h
          </Text>
        </Box>
        {showReceiveBlock && <SendReceiveBlock type="receive" />}
      </Box>
    </Box>
  );
};

export default AmountTab;

import { Box, IconButton, Image, useBreakpointValue } from '@chakra-ui/react';
import { gsap } from 'gsap';
import React, { useLayoutEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarTL } from '../../assets/animation/Sidebar';
import coveyLogo from '../../assets/images/svgs/coveyLogo.svg';
import ExpandedLogo from '../../assets/images/svgs/ExpandedLogo.svg';
import { ArrowIcon } from '../../Icons/ArrowDown';
import { LeftArrow } from '../../Icons/ArrowLeft';
import { SidebarOption, SidebarProps } from '../../interfaces';
import SidebarItem from './SidebarItem';

function Sidebar({ sideBarOpen, activeView, sidebarOptions, setActiveView, setSideBarOpen }: SidebarProps) {
  const SidebarRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    md2: false,
  });

  useLayoutEffect(() => {
    if (!SidebarRef.current || isMobile) return;
    const ctx = gsap.context(() => {
      SidebarTL(SidebarRef.current);
    }, SidebarRef);
    return () => ctx.revert();
  }, [isMobile]);

  if (isMobile) {
    return (
      <>
        {/* Mobile Sidebar */}
        <Box
          className="mobile-sidebar"
          position="fixed"
          top="0"
          left={sideBarOpen ? '0' : '-100%'}
          height="100vh"
          width="70%"
          backgroundColor="#353535"
          transition="left 0.3s ease-in-out"
          zIndex={3}
          padding="20px"
          overflowY="auto"
        >
          <Box marginBottom="40px">
            <Image src={ExpandedLogo} alt="Logo" />
          </Box>

          <Box display="flex" flexDirection="column" height="calc(100vh - 100px)" position="relative">
            {/* Main menu items */}
            <Box display="flex" flexDirection="column" gap="10px" flex="1">
              {sidebarOptions.map((sideBarOption: SidebarOption, index: number) => {
                // Check if it's settings or logout
                const isSettingsOrLogout = sideBarOption.key === 'settings' || sideBarOption.key === 'logout';

                // Skip settings and logout here - we'll render them separately
                if (isSettingsOrLogout) return null;

                return (
                  <Box key={sideBarOption.key}>
                    <NavLink
                      to={sideBarOption.href}
                      onClick={() => {
                        setActiveView(sideBarOption.key);
                        setSideBarOpen(false);
                      }}
                    >
                      <SidebarItem
                        sideBarItemKey={sideBarOption.key}
                        sideBarIcon={sideBarOption.sideBarIcon}
                        text={sideBarOption.text}
                        sideBarOpen={true}
                        active={activeView === sideBarOption.key}
                        setActiveView={() => setActiveView(sideBarOption.key)}
                        last={false}
                      />
                    </NavLink>
                  </Box>
                );
              })}
            </Box>

            {/* Settings and Logout at bottom */}
            <Box position="absolute" bottom="20px" left="0" right="0" display="flex" flexDirection="column" gap="10px">
              {sidebarOptions
                .filter((option) => option.key === 'settings' || option.key === 'logout')
                .map((sideBarOption: SidebarOption) => (
                  <Box key={sideBarOption.key}>
                    <NavLink
                      to={sideBarOption.href}
                      onClick={() => {
                        setActiveView(sideBarOption.key);
                        setSideBarOpen(false);
                      }}
                    >
                      <SidebarItem
                        sideBarItemKey={sideBarOption.key}
                        sideBarIcon={sideBarOption.sideBarIcon}
                        text={sideBarOption.text}
                        sideBarOpen={true}
                        active={activeView === sideBarOption.key}
                        setActiveView={() => setActiveView(sideBarOption.key)}
                        last={true}
                      />
                    </NavLink>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box
      className="sidebar-container"
      ref={SidebarRef}
      minWidth={sideBarOpen ? ['190px', '278px'] : '98px'}
      transition="0.2s all"
      padding="12px 24px 40px 24px"
      cursor="pointer"
      display={[sideBarOpen ? 'flex' : 'none', sideBarOpen ? 'flex' : 'none', 'flex']}
      flexDirection="column"
      position={['absolute', 'absolute', 'relative']}
      justifyContent="space-between"
      bg={['#353535', '#353535', 'unset']}
      h="100%"
      zIndex={2}
      style={{ transition: 'all 0.3s 0s ease-out' }}
    >
      <Box
        className="sidebar-wrap"
        width={sideBarOpen ? ['190px', '230px'] : 'fit-content'}
        style={{ transition: 'all 0.3s 0s ease-out' }}
      >
        {/* Logo Section */}
        <Box
          padding={0}
          display="flex"
          justifyContent={sideBarOpen ? 'flex-start' : 'center'}
          alignItems="center"
          gap={3}
          paddingBottom={sideBarOpen ? '24px' : '14px'}
          borderBottom="1px solid var(--Neutrals-7, #494949)"
          style={{ transition: 'all 0.3s 0s ease-out' }}
        >
          <Box className="gsap-item-logo" display="flex" gap={3} style={{ width: '100%' }}>
            {!sideBarOpen ? (
              <Image src={coveyLogo} alt="Collapsed Logo" />
            ) : (
              <Image src={ExpandedLogo} alt="Expanded Logo" />
            )}
          </Box>
        </Box>

        {/* Toggle Button */}
        <Box
          className="gsap-item-btn"
          display="flex"
          margin="24px 0"
          justifyContent={[sideBarOpen ? 'right' : 'center']}
          alignItems="center"
          right="-20px"
          style={{ transition: 'all 0.3s 0s ease-out' }}
        >
          <IconButton
            background="transparent"
            _hover={{ background: 'transparent' }}
            onClick={() => setSideBarOpen(!sideBarOpen)}
            icon={sideBarOpen ? <LeftArrow boxSize={6} /> : <ArrowIcon boxSize={6} />}
            aria-label="Toggle Sidebar"
          />
        </Box>

        {/* Sidebar Items */}
        {/* Main Sidebar Items */}
        <Box
          className="SidebarItem-wrap"
          style={{ overflow: 'hidden' }}
          display="flex"
          width="inherit"
          flexDirection="column"
          position="relative"
          gap="10px"
          height="75%"
        >
          {sidebarOptions.map((sideBarOption: SidebarOption) => {
            // Skip settings and logout here - we'll render them separately
            if (sideBarOption.key === 'settings' || sideBarOption.key === 'logout') return null;

            return (
              <Box className="gsap-item" key={sideBarOption.key}>
                <NavLink to={sideBarOption.href}>
                  <SidebarItem
                    sideBarItemKey={sideBarOption.key}
                    sideBarIcon={sideBarOption.sideBarIcon}
                    text={sideBarOption.text}
                    sideBarOpen={sideBarOpen}
                    active={activeView === sideBarOption.key}
                    setActiveView={() => setActiveView(sideBarOption.key)}
                    last={false}
                  />
                </NavLink>
              </Box>
            );
          })}
        </Box>

        {/* Settings and Logout at bottom */}
        <Box
          position="absolute"
          bottom="50px"
          left="24px"
          right="24px"
          display="flex"
          flexDirection="column"
          gap="10px"
        >
          {sidebarOptions
            .filter((option) => option.key === 'settings' || option.key === 'logout')
            .map((sideBarOption: SidebarOption) => (
              <Box className="gsap-item" key={sideBarOption.key}>
                <NavLink to={sideBarOption.href}>
                  <SidebarItem
                    sideBarItemKey={sideBarOption.key}
                    sideBarIcon={sideBarOption.sideBarIcon}
                    text={sideBarOption.text}
                    sideBarOpen={sideBarOpen}
                    active={activeView === sideBarOption.key}
                    setActiveView={() => setActiveView(sideBarOption.key)}
                    last={true}
                  />
                </NavLink>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;

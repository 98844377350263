import { Box, Button, Center, Image, Input, useColorModeValue, useToast } from '@chakra-ui/react';
import { Code, File, FileArchive, FileText, Image as ImageIcon, LucideIcon, Music, Upload, Video } from 'lucide-react';
import React, { ChangeEvent, DragEvent, useRef, useState } from 'react';
import CSV from '../../assets/images/svgs/CSV.svg';
import FileUploaded from '../../assets/images/svgs/FileUploaded.svg';
import Text from './Text';

interface FileUploaderProps {
  onFileSelect: (file: File) => void;
  maxFileSize?: number;
}

const getFileIcon = (type: string | undefined): LucideIcon => {
  if (!type) return Upload;

  if (type.startsWith('image/')) return ImageIcon;
  if (type.startsWith('video/')) return Video;
  if (type.startsWith('audio/')) return Music;
  if (type.startsWith('text/')) return FileText;
  if (type.includes('pdf')) return FileText;
  if (type.includes('zip') || type.includes('rar') || type.includes('7z')) return FileArchive;
  if (type.includes('code') || type.includes('javascript') || type.includes('json')) return Code;

  return File;
};

interface FileInfo {
  name: string;
  size: string;
  type: string;
  lastModified: string;
}

const formatFileSize = (bytes: number): string => {
  if (!bytes) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const FileUploader: React.FC<FileUploaderProps> = ({ onFileSelect, maxFileSize = 50 * 1024 * 1024 }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const dragBorderColor = useColorModeValue('blue.500', 'blue.400');
  const dragBgColor = useColorModeValue('blue.50', 'blue.900');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (): void => {
    setIsDragging(false);
  };

  const validateFile = (file: File): boolean => {
    if (file.size > maxFileSize) {
      toast({
        title: 'File too large',
        description: `File size should be less than ${formatFileSize(maxFileSize)}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    // Validate file type (only allow CSV)
    if (!file.name.toLowerCase().endsWith('.csv')) {
      toast({
        title: 'Invalid file type',
        description: 'Please upload a CSV file',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const processFile = (file: File): void => {
    if (!validateFile(file)) return;

    const info: FileInfo = {
      name: file.name,
      size: formatFileSize(file.size),
      type: file.type || 'text/csv',
      lastModified: new Date(file.lastModified).toLocaleDateString(),
    };

    setFileInfo(info);
    onFileSelect(file);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      processFile(file);
    }
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  const handleBrowseClick = (): void => {
    fileInputRef.current?.click();
  };

  return (
    <Center
      display="flex"
      padding="var(--margin, 16px) var(--Button-paddingContentHorizontal, 16px)"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="16px"
      alignSelf="stretch"
      borderRadius="36px"
      border={`1px dashed ${isDragging ? 'rgba(64, 150, 255, 0.5)' : 'rgba(0, 0, 0, 0.15)'}`}
      backgroundColor={isDragging ? 'rgba(64, 150, 255, 0.1)' : 'transparent'}
      transition="all 0.2s ease-in-out"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleBrowseClick}
      cursor="pointer"
      _hover={{
        borderColor: 'rgba(64, 150, 255, 0.5)',
        backgroundColor: 'rgba(64, 150, 255, 0.05)',
      }}
    >
      <Input ref={fileInputRef} type="file" display="none" onChange={handleFileSelect} accept=".csv" />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="var(--Button-marginXS, 8px)"
        alignSelf="stretch"
        padding={'24px 0px'}
      >
        <Image
          src={fileInfo ? FileUploaded : CSV}
          alt={fileInfo ? 'File Icon' : 'CSV Icon'}
          width="48px"
          height="48px"
        />

        <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
          {fileInfo ? 'File uploaded' : 'Upload your file'}
        </Text>
      </Box>

      <Box
        display="flex"
        padding="6px 6px 6px 16px"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
        alignSelf="stretch"
        borderRadius="20px"
        border="0.5px solid var(--Neutrals-4, #E9E9E9)"
        background="var(--Neutrals-2, #F6F6F6)"
        width={'100%'}
      >
        <Text
          c="var(--Text-Primary-description, #909090)"
          fs="14px"
          fw={400}
          lineHeight={'22px'}
          textAlign={'left'}
          noOfLines={1}
        >
          {fileInfo ? fileInfo.name : 'Browse to add your file'}
        </Text>
        <Button
          display="flex"
          px="var(--Button-paddingContentHorizontal, 16px)"
          py="6px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="16px"
          border="1px solid rgba(0, 0, 0, 0.15)"
          color="rgba(0, 0, 0, 0.88)"
          fontFamily="Poppins"
          fontSize="14px"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="22px"
          bg="var(--Button-colorBgContainer, #FFF)"
          _hover={{
            bg: 'var(--Button-colorBgContainerHover, #F6F6F6)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleBrowseClick();
          }}
        >
          {fileInfo ? 'Change File' : 'Browse'}
        </Button>
      </Box>
    </Center>
  );
};

export default FileUploader;

// src/context/ScrollContext.tsx
import React, { createContext, useContext } from 'react';
import { useScroll } from '../hooks/useScroll';

interface ScrollContextType {
  isScrolled: boolean;
}

const ScrollContext = createContext<ScrollContextType>({ isScrolled: false });

export const ScrollProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const scrollState = useScroll();

  return <ScrollContext.Provider value={scrollState}>{children}</ScrollContext.Provider>;
};

export const useScrollContext = () => useContext(ScrollContext);

import { Sankey } from '@antv/g2plot';
import { Box, Flex } from '@chakra-ui/react';
import { message } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { BlockDataParams, SankeyParamsSingle } from '../api/ApiCalls/user';
import Block from '../components/common/Block';
import FetchTx from '../components/common/FetchTx';
import Text from '../components/common/Text';
import TimeTab from '../components/common/TimeTab';
import { useLoginStore } from '../store';

const TimeTabData = [
  { key: 'year', title: 'Year' },
  { key: 'month', title: 'Month' },
  { key: 'week', title: 'Week' },
  { key: 'custom', title: 'Custom' },
];

interface SankeyData {
  source: string;
  target: string;
  value: number;
  source_name?: string;
  target_name?: string;
}

interface TransformedSankeyData {
  source: string;
  target: string;
  value: number;
  sourceAddress: string;
  targetAddress: string;
}

interface SankeyEventData {
  data: {
    data: TransformedSankeyData;
  };
}

interface SankeyLabelEventData {
  data: {
    data: {
      name: string;
    };
  };
}

const Demo = () => {
  const [selectedBlock, setSelectedBlock] = useState<string>('Overall');
  const [selectedTime, setSelectedTime] = useState<string>('week');
  const [blockData, setBlockData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Sankey | null>(null);
  const navigate = useNavigate();
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

  useEffect(() => {
    if (selectedTime !== 'custom' || (dateRange[0] && dateRange[1])) {
      fetchBlockData();
    }
  }, [selectedTime, dateRange]);

  useEffect(() => {
    if (selectedBlock && (selectedTime !== 'custom' || (dateRange[0] && dateRange[1]))) {
      fetchSankeyData(selectedBlock);
    }
  }, [selectedBlock, selectedTime, dateRange]);

  const fetchBlockData = async () => {
    setLoading(true);
    const params: BlockDataParams = { time_range: selectedTime };
    if (selectedTime === 'custom' && dateRange[0] && dateRange[1]) {
      params.start_date = new Date(dateRange[0]).toISOString().split('T')[0];
      params.end_date = new Date(dateRange[1]).toISOString().split('T')[0];
    }

    const { data, error } = await api.getBlockData(params);

    if (error) {
      message.error(error);
    } else {
      const newBlockData = Object.entries(data).map(([title, data]: [string, any]) => ({
        key: title,
        address: data.address,
        box: data.is_receiver === null ? '' : data.is_receiver ? 'Receiver' : 'Sender',
        title: title,
        isActive: title !== 'Overall',
        amount: Number(data.total_amount).toLocaleString(undefined, { maximumFractionDigits: 2 }),
      }));

      const overallBlock = newBlockData.find((block) => block.title === 'Overall');
      const otherBlocks = newBlockData.filter((block) => block.title !== 'Overall');
      setBlockData([overallBlock, ...otherBlocks]);
    }

    setLoading(false);
  };

  const fetchSankeyData = async (title: string) => {
    setLoading(true);
    const params: SankeyParamsSingle = {
      time_range: selectedTime,
      inflow_outflow_title: title,
    };
    if (selectedTime === 'custom' && dateRange[0] && dateRange[1]) {
      params.start_date = new Date(dateRange[0]).toISOString().split('T')[0];
      params.end_date = new Date(dateRange[1]).toISOString().split('T')[0];
    }

    const { data, error } = await api.getSankeyTransactionsSingle(params);

    if (error) {
      message.error(error);
    } else {
      renderSankey(data);
    }

    setLoading(false);
  };

  const navigateToSinglePage = (address: string) => {
    setSelectedWalletAddress(address);
    navigate(`/demo/${address}`, {
      state: {
        address: address,
        labels: [],
      },
    });
  };

  const formatAddress = (address: string) => {
    if (!address) return '';
    if (!address.startsWith('0x')) return address;
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const renderSankey = (data: SankeyData[]) => {
    if (containerRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const transformedData = data.map((item) => ({
        source: item.source_name || formatAddress(item.source),
        target: item.target_name || formatAddress(item.target),
        value: item.value,
        sourceAddress: item.source,
        targetAddress: item.target,
      }));

      chartRef.current = new Sankey(containerRef.current, {
        data: transformedData,
        sourceField: 'source',
        targetField: 'target',
        weightField: 'value',
        nodeWidthRatio: 0.01,
        nodePaddingRatio: 0.03,
        nodeDraggable: true,

        // Node styling with custom colors
        nodeStyle: {
          opacity: 0.8,
          fillOpacity: 0.8,
          stroke: '#fff',
          strokeWidth: 1,
          nodeGap: 5,
        },

        // Edge styling
        edgeStyle: {
          opacity: 0.6,
          fillOpacity: 0.6,
          strokeWidth: 0,
          spacing: 4,
        },

        // Use color array for gradient-like effect
        color: ['#FF6B6B', '#4ECDC4', '#FFC90A', '#5654D4', '#3FC8E4', '#1DBF73', '#FB3B52'],

        // Label configuration
        label: {
          style: {
            fill: '#333',
            fontSize: 12,
            fontWeight: 500,
          },
        },

        // Tooltip configuration
        tooltip: {
          fields: ['source', 'target', 'value'],
          formatter: (datum) => {
            const valueFormatted = Math.floor(datum.value).toLocaleString();
            return {
              name: `${datum.source} → Mexc ${valueFormatted} `,
              value: '',
            };
          },
        },

        // Animation configuration
        animation: {
          appear: {
            animation: 'fade-in',
            duration: 1000,
          },
        },
      });

      // Event handlers remain the same
      chartRef.current.on('element:click', (evt: SankeyEventData) => {
        const nodeData = data.find(
          (item) =>
            item.source_name === evt.data.data.source ||
            item.source === evt.data.data.source ||
            item.target_name === evt.data.data.target ||
            item.target === evt.data.data.target,
        );

        const address = nodeData?.source || nodeData?.target || evt.data.data.source;
        navigateToSinglePage(address);
        copyToClipboard(address);
      });

      chartRef.current.on('label:click', (evt: SankeyLabelEventData) => {
        const nodeName = evt.data.data.name;
        const nodeData = data.find(
          (item) =>
            item.source_name === nodeName ||
            item.source === nodeName ||
            item.target_name === nodeName ||
            item.target === nodeName,
        );

        const address = nodeData?.source || nodeData?.target || nodeName;
        navigateToSinglePage(address);
        copyToClipboard(address);
      });

      chartRef.current.render();
    }
  };

  const copyToClipboard = debounce((text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Address copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  }, 100);

  const handleBlockClick = (key: string, title: string) => {
    setSelectedBlock(title);
  };

  const handleTimeTabClick = (key: string) => {
    setSelectedTime(key);
    if (key !== 'custom') {
      setDateRange([null, null]);
    }
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
  };

  return (
    <Flex
      gap={'24px'}
      padding={['24px 12px', '24px 12px', '24px 12px', '24px ']}
      width={'100%'}
      flexDirection={'column'}
      background={['var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'transparent']}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          gap: '10px',
          alignSelf: 'stretch',
        }}
      >
        <Text fs={['24px', '24px', '24px', '36px']} lineHeight={'28px'}>
          Sanky Charts
        </Text>
        <Text fs={'14px'} fw={400} lineHeight={'22px'}>
          Track all your wallet’s activity in one place.
        </Text>
      </Box>
      <div style={{ overflow: 'auto', width: '100%', gap: '10px' }}>
        <Box display={'inline-flex'} flexWrap={'nowrap'} gap={'10px'} padding={['0px', '0px', '10px']}>
          {blockData.map((item) => (
            <Block
              key={item.key}
              address={item.address}
              box={item.box}
              title={item.title}
              isActive={item.isActive}
              amount={item.amount}
              selected={selectedBlock === item.title}
              onClick={() => handleBlockClick(item.key, item.title)}
            />
          ))}
        </Box>
      </div>

      <Box
        borderRadius={['0px', '0px', '0px', '36px']}
        border={['transparent', 'transparent', 'transparent', '1px solid rgba(255, 255, 255, 0.10)']}
        borderBottom={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        borderTop={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        background="var(--Neutrals-1, #FFF)"
        boxShadow={['transparent', 'transparent', 'transparent', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
        display="flex"
        padding={['24px 0px', '24px 0px', '24px 0px', '24px']}
        flexDirection="column"
        alignItems="flex-end"
        gap="24px"
        alignSelf="stretch"
      >
        <Box
          width={['100%', '100%', '100%', 'fit-content']}
          display="flex"
          justifyContent="flex-end"
          alignItems={['flex-end', 'flex-end', 'flex-end', 'flex-end']}
          gap="20px"
          flexDirection={['column', 'column', 'column', 'row']}
        >
          {selectedTime === 'custom' && <FetchTx onFetch={handleDateRangeChange} />}
          <Box
            display="flex"
            cursor={'pointer'}
            padding="5px"
            alignItems="center"
            gap="5px"
            borderRadius="14px"
            width={['100%', '100%', '100%', 'fit-content']}
            background="var(--Neutrals-2, #F6F6F6)"
          >
            {TimeTabData.map((item) => (
              <TimeTab
                key={item.key}
                title={item.title}
                selected={selectedTime === item.key}
                onClick={() => handleTimeTabClick(item.key)}
              />
            ))}
          </Box>
        </Box>
        <div ref={containerRef} style={{ width: '100%', height: 600 }} />
      </Box>
    </Flex>
  );
};

export default Demo;

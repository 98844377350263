// import { AddIcon } from '@chakra-ui/icons';
// import { Box, Text } from '@chakra-ui/react';
// import { Button as AntButton, Pagination, Space, Table } from 'antd';
// import { ColumnType } from 'antd/es/table';
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from '../api/axios';
// import AddressBookEditModal from '../components/modals/AddressBookEditModal';
// import DeleteModal from '../components/modals/DeleteModal';
// import { useLoginStore } from '../store';

// export interface DataType {
//   id: number;
//   wallet_address: string;
//   name: string;
//   created_at: string;
//   updated_at: string;
//   balance: number;
// }

// interface PaginatedApiResponse {
//   count: number;
//   next: string | null;
//   previous: string | null;
//   results: DataType[];
// }

// interface ApiResponse {
//   count: number;
//   next: string | null;
//   previous: string | null;
//   results: DataType[];
// }

// import { Input } from 'antd';
// const { Search } = Input;

// const AddressBook: React.FC = () => {
//   const [tableData, setTableData] = useState<DataType[]>([]);
//   const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
//   const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
//   const [editingRecord, setEditingRecord] = useState<DataType | null>(null);
//   const [deletingRecord, setDeletingRecord] = useState<DataType | null>(null);
//   const [isAddingNew, setIsAddingNew] = useState(false);
//   const [totalItems, setTotalItems] = useState<number>(0);
//   const [searchQuery, setSearchQuery] = useState<string>('');
//   const [currentPage, setCurrentPage] = useState<number>(() => {
//     const savedPage = localStorage.getItem('addressBooksPageIndex');
//     return savedPage ? parseInt(savedPage, 10) : 1;
//   });
//   const [pageSize, setPageSize] = useState<number>(() => {
//     const savedSize = localStorage.getItem('addressBooksPageSize');
//     return savedSize ? parseInt(savedSize, 10) : 10;
//   });
//   const navigate = useNavigate();
//   const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

//   useEffect(() => {
//     fetchData(currentPage, pageSize);
//   }, [currentPage, pageSize, searchQuery]);

//   useEffect(() => {
//     localStorage.setItem('addressBooksPageIndex', currentPage.toString());
//     localStorage.setItem('addressBooksPageSize', pageSize.toString());
//   }, [currentPage, pageSize]);

//   const fetchData = async (page: number, size: number) => {
//     try {
//       const response = await axios.get<PaginatedApiResponse>(
//         `/api/user/address-book/?page=${page}&page_size=${size}&search=${searchQuery}`,
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
//         },
//       );
//       const sortedData = [...response.data.results].sort((a, b) => b.balance - a.balance);
//       setTableData(sortedData);
//       setTotalItems(response.data.count);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const navigateToSinglePage = (address: string) => {
//     setSelectedWalletAddress(address);
//     navigate(`/addressBooks/${address}`, {
//       state: {
//         address: address,
//         labels: [],
//       },
//     });
//   };

//   const handleEdit = (record: DataType) => {
//     setEditingRecord(record);
//     setIsAddingNew(false);
//     setIsShowSuccessModal(true);
//   };

//   const handleDelete = (record: DataType) => {
//     setDeletingRecord(record);
//     setIsShowDeleteModal(true);
//   };

//   const handleAdd = () => {
//     setEditingRecord(null);
//     setIsAddingNew(true);
//     setIsShowSuccessModal(true);
//   };

//   const handleSearch = (value: string) => {
//     setSearchQuery(value);
//     setCurrentPage(1);
//     localStorage.setItem('addressBooksPageIndex', '1');
//   };

//   const handleSaveChanges = async (updatedRecord: DataType) => {
//     try {
//       if (isAddingNew) {
//         await axios.put(
//           '/api/user/address-book/',
//           {
//             wallet_address: updatedRecord.wallet_address,
//             name: updatedRecord.name,
//           },
//           {
//             headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
//           },
//         );
//       } else {
//         await axios.patch(
//           '/api/user/address-book/',
//           {
//             id: updatedRecord.id,
//             wallet_address: updatedRecord.wallet_address,
//             name: updatedRecord.name,
//           },
//           {
//             headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
//           },
//         );
//       }
//       fetchData(currentPage, pageSize);
//       setIsShowSuccessModal(false);
//       setEditingRecord(null);
//       setIsAddingNew(false);
//     } catch (error) {
//       console.error('Error saving changes:', error);
//     }
//   };

//   const handleConfirmDelete = async () => {
//     if (deletingRecord) {
//       try {
//         await axios.delete('/api/user/address-book/', {
//           data: { id: deletingRecord.id },
//           headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
//         });
//         fetchData(currentPage, pageSize);
//         setIsShowDeleteModal(false);
//         setDeletingRecord(null);
//       } catch (error) {
//         console.error('Error deleting record:', error);
//       }
//     }
//   };

//   const handlePageChange = (page: number, size?: number) => {
//     setCurrentPage(page);
//     if (size) setPageSize(size);
//     localStorage.setItem('addressBooksPageIndex', page.toString());
//     localStorage.setItem('addressBooksPageSize', size ? size.toString() : pageSize.toString());
//   };

//   const getUniqueFilters = (data: DataType[], key: keyof DataType) => {
//     const uniqueValues = Array.from(new Set(data.map((item) => item[key])));
//     return uniqueValues.map((value) => ({ text: value, value }));
//   };

//   const columns: ColumnType<DataType>[] = [
//     {
//       title: 'Address',
//       dataIndex: 'wallet_address',
//       key: 'wallet_address',
//       sorter: (a, b) => a.wallet_address.localeCompare(b.wallet_address),
//       render: (text: string) => (
//         <AntButton
//           type="link"
//           onClick={() => navigateToSinglePage(text)}
//           style={{ color: '#2D8DFB', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
//         >
//           {`${text}`}
//         </AntButton>
//       ),
//     },
//     {
//       title: 'Balance',
//       dataIndex: 'balance',
//       key: 'balance',
//       sorter: (a, b) => b.balance - a.balance,
//       defaultSortOrder: 'ascend',
//       render: (balance: any) => {
//         const numBalance = Number(balance);
//         return isNaN(numBalance) ? '0.00' : numBalance.toFixed(2);
//       },
//     },
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//       filters: getUniqueFilters(tableData, 'name'),
//       onFilter: (value, record) => record.name.indexOf(value as string) === 0,
//     },
//     {
//       title: 'Action',
//       key: 'action',
//       render: (_, record) => (
//         <Space size="middle">
//           <AntButton
//             type="link"
//             onClick={() => handleEdit(record)}
//             style={{ color: '#2D8DFB', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
//           >
//             Edit
//           </AntButton>
//           <AntButton
//             type="link"
//             onClick={() => handleDelete(record)}
//             style={{ color: '#FF4D4F', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
//           >
//             Delete
//           </AntButton>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <Box marginTop="40px">
//       <Text
//         color="rgba(0, 0, 0, 0.88)"
//         fontSize="14px"
//         fontStyle="normal"
//         fontWeight="400"
//         lineHeight="22px"
//         marginBottom="8px"
//       >
//         My Address Book
//       </Text>
//       <Search
//         placeholder="Search by wallet address or name"
//         allowClear
//         enterButton="Search"
//         size="large"
//         onSearch={handleSearch}
//         style={{ maxWidth: '500px', marginBottom: '16px' }}
//       />
//       <AntButton type="primary" icon={<AddIcon />} onClick={handleAdd} style={{ marginBottom: '16px' }}>
//         Add New Address
//       </AntButton>
//       <Table columns={columns} dataSource={tableData} rowKey="id" pagination={false} />
//       <Pagination
//         current={currentPage}
//         total={totalItems}
//         pageSize={pageSize}
//         onChange={handlePageChange}
//         showSizeChanger
//         showQuickJumper
//         showTotal={(total) => `Total ${total} items`}
//         pageSizeOptions={['10', '50', '100']}
//         style={{ marginTop: '16px', textAlign: 'right' }}
//       />
//       <AddressBookEditModal
//         isShowModal={isShowSuccessModal}
//         setIsShowModal={setIsShowSuccessModal}
//         editingRecord={editingRecord}
//         onSaveChanges={handleSaveChanges}
//         isAddingNew={isAddingNew}
//       />
//       <DeleteModal
//         showModal={isShowDeleteModal}
//         isShowModal={setIsShowDeleteModal}
//         onConfirmDelete={handleConfirmDelete}
//       />
//     </Box>
//   );
// };

// export default AddressBook;

import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import AddressBookBG from '../assets/images/pngs/AddressBookBG.png';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import ThreeDots from '../components/common/ThreeDots';
import AddressBookEditModal from '../components/modals/AddressBookEditModal';
import DeleteModal from '../components/modals/DeleteModal';
import { BalanceFilter } from '../Icons/BalanceFilter';
import { BalanceIcon } from '../Icons/BalanceIcon';
import { FilterTag } from '../Icons/FilterTag';
import { PlusIcon } from '../Icons/PlusIcon';
import { TagIcon } from '../Icons/TagIcon';
import { loadingStore, useLoginStore } from '../store';
import { TokenHolder } from '../types/index';
import { numberWithCommas } from '../utils/helper';
import TablePagination from './TablePagination';

// interface TokenHolder {
//   id: number;
//   wallet_address: string;
//   name: string;
//   balance: string;
//   contract_decimals: string;
//   labels: string[];
//   created_at: string;
//   updated_at: string;
// }

interface PaginatedApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: TokenHolder[];
}

const AddressBook = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);
  const [tokenHolders, setTokenHolder] = useState<TokenHolder[]>([]);
  const [pageIndex, setPageIndex] = useState(() => {
    const savedPage = localStorage.getItem('tokenHoldersPageIndex');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [pageSize, setPageSize] = useState(() => {
    const savedSize = localStorage.getItem('tokenHoldersPageSize');
    return savedSize ? parseInt(savedSize, 10) : 10;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [balanceSort, setBalanceSort] = useState<'none' | 'asc' | 'desc'>('desc');
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedHolder, setSelectedHolder] = useState<TokenHolder | null>(null);
  const [deletingRecord, setDeletingRecord] = useState<TokenHolder | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  // Data fetching
  // Update the getTokenHolders function
  const getTokenHolders = async () => {
    setLoading(true);
    try {
      const response = await axios.get<PaginatedApiResponse>(
        `/api/user/address-book/?page=${pageIndex}&page_size=${pageSize}&search=${searchQuery}`, // Changed endpoint
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        const results = response.data.results.map((holder) => ({
          id: holder.id,
          wallet_address: holder.wallet_address || '',
          name: holder.name || '', // This should now be populated from the address book API
          balance: holder.balance || '0',
          contract_decimals: holder.contract_decimals || '18',
          labels: holder.labels || [],
          created_at: holder.created_at || new Date().toISOString(),
          updated_at: holder.updated_at || new Date().toISOString(),
          address: holder.wallet_address || '',
        })) as TokenHolder[];

        console.log('API Response:', response.data); // Add this to debug
        console.log('Mapped Results:', results); // Add this to debug

        setTokenHolder(results);
        setTotalCount(response.data.count);
      }
    } catch (err) {
      console.error('Error fetching token holders:', err);
      toast({
        title: 'Error fetching token holders',
        description: 'Please try again later',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  // Search handlers
  const executeSearch = () => {
    setSearchQuery(searchInput);
    setPageIndex(1);
    localStorage.setItem('tokenHoldersPageIndex', '1');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      executeSearch();
    }
  };

  // Handlers
  const handleSaveChanges = async (updatedRecord: TokenHolder) => {
    setLoading(true);
    try {
      if (isAddingNew) {
        await axios.put(
          '/api/user/address-book/',
          {
            wallet_address: updatedRecord.wallet_address,
            name: updatedRecord.name,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      } else {
        await axios.patch(
          '/api/user/address-book/',
          {
            id: updatedRecord.id,
            wallet_address: updatedRecord.wallet_address,
            name: updatedRecord.name,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      }

      await getTokenHolders();
      setIsShowSuccessModal(false);
      setSelectedHolder(null);
      setIsAddingNew(false);
      toast({
        title: 'Changes saved successfully',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving changes:', error);
      toast({
        title: 'Error saving changes',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (!deletingRecord) return;
    setLoading(true);
    try {
      await axios.delete('/api/user/address-book/', {
        data: { id: deletingRecord.id },
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      await getTokenHolders();
      setIsShowDeleteModal(false);
      setDeletingRecord(null);
      toast({
        title: 'Record deleted successfully',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting record:', error);
      toast({
        title: 'Error deleting record',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setSelectedHolder(null);
    setIsAddingNew(true);
    setIsShowSuccessModal(true);
  };

  const handleBalanceSort = () => {
    const nextSort = balanceSort === 'none' ? 'asc' : balanceSort === 'asc' ? 'desc' : 'none';
    setBalanceSort(nextSort);

    setTokenHolder((prevHolders) => {
      const sortedHolders = [...prevHolders].sort((a, b) => {
        const balanceA = Number(a.balance) / Math.pow(10, Number(a.contract_decimals));
        const balanceB = Number(b.balance) / Math.pow(10, Number(b.contract_decimals));

        if (nextSort === 'asc') {
          return balanceA - balanceB;
        } else if (nextSort === 'desc') {
          return balanceB - balanceA;
        }
        return 0;
      });
      return sortedHolders;
    });
  };

  const navigateToSinglePage = (address: string, labels: string[]) => {
    setSelectedWalletAddress(address);
    navigate(`/addressBooks/${address}`, {
      state: {
        address: address,
        labels: labels || [], // Added fallback for labels
      },
    });
  };

  const handleNameClick = (holder: TokenHolder) => {
    setSelectedHolder(holder);
    setIsAddingNew(false);
    setIsShowSuccessModal(true);
  };

  const calculateAdjustedBalance = (balance: string) => {
    const adjustedBalance = Number(balance);
    return numberWithCommas(adjustedBalance.toFixed(2));
  };

  // Effects
  useEffect(() => {
    getTokenHolders();
  }, [pageIndex, pageSize, searchQuery]);

  useEffect(() => {
    localStorage.setItem('tokenHoldersPageIndex', pageIndex.toString());
    localStorage.setItem('tokenHoldersPageSize', pageSize.toString());
  }, [pageIndex, pageSize]);

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    );
  }

  return (
    <Box
      display="flex"
      padding={['24px 12px', '24x 12px', '24x 12px', '14px 24px 40px 24px']}
      flexDirection="column"
      alignItems="flex-start"
      gap="24px"
      flex="1 0 0"
      alignSelf="stretch"
      background={['var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'transparent']}
    >
      {/* Header Section */}
      <Box
        display="flex"
        height="200px"
        padding="24px"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        alignSelf="stretch"
        borderRadius="24px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        backgroundImage={`url(${AddressBookBG})`}
        backgroundSize="cover"
        backgroundPosition="center"
        gap="16px"
        margin={0}
      >
        <Text
          color="var(--Cascader-colorWhite, #FFF)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize={['24px', '24px', '24px', '36px']}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="28px"
        >
          Your Address Book
        </Text>
        <Text
          color="var(--Text-Secondary-default, #FFF)"
          fontFamily="Poppins"
          fontSize="14px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="22px"
        >
          Stay on top of your wallets with customized tags and real-time balances.
        </Text>
      </Box>

      {/* Main Content Section */}
      <Box
        display="flex"
        flexDirection="column"
        borderRadius={['0px', '0px', '0px', '36px']}
        width={'100%'}
        gap={'24px'}
        border={['transparent', 'transparent', 'transparent', '1px solid rgba(255, 255, 255, 0.10)']}
        borderBottom={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        borderTop={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        background="var(--Neutrals-1, #FFF)"
        boxShadow={['transparent', 'transparent', 'transparent', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
        padding={['24px 0px', '24px 0px', '24px 0px', '24px']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
      >
        {/* Search and Add Section */}
        <Flex
          width="100%"
          justify="space-between"
          gap="10px"
          align={['flex-start', 'flex-start', 'center']}
          flexDirection={['column', 'column', 'row']}
        >
          <InputGroup
            width={['100%', '100%', 'fit-content']}
            padding="0px 20px 0px 12px"
            gap="10px"
            border="0.5px solid var(--Neutrals-4, #E9E9E9)"
            borderRadius="16px"
            background="var(--Neutrals-2, #F6F6F6)"
            alignItems="center"
          >
            <Image src={SearchIcon} alt="search" cursor="pointer" onClick={executeSearch} />
            <Input
              border="none"
              color="#000"
              padding={0}
              placeholder="Search by address or tag"
              background="transparent"
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              value={searchInput}
              _placeholder={{
                color: 'var(--Text-Primary-description, #909090)',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '500',
              }}
              _focus={{
                border: 'none',
                boxShadow: 'none',
              }}
            />
          </InputGroup>

          <Button
            display="flex"
            padding="6px 16px"
            alignItems="center"
            gap="8px"
            borderRadius="16px"
            border="1px solid #2D8DFB"
            background="#2D8DFB"
            color="#FFF"
            fontFamily="Poppins"
            fontSize="14px"
            fontWeight="500"
            lineHeight="22px"
            onClick={handleAdd}
            _hover={{
              background: '#1E76D3',
              borderColor: '#1E76D3',
            }}
            _active={{
              background: '#145BA1',
              borderColor: '#145BA1',
            }}
          >
            <PlusIcon fill="#FFF" />
            Add New Address
          </Button>
        </Flex>

        {isMobile ? (
          <Box
            width="100%"
            overflowY="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
                background: '#F2F2F2',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#CCCCCC',
                borderRadius: '24px',
              },
            }}
          >
            <Table variant="unstyled">
              <Thead width={'791px'}>
                <Tr
                  display="flex"
                  height="40px"
                  padding="0px 12px"
                  alignItems="center"
                  borderRadius="12px"
                  background="var(--Neutrals-2, #F6F6F6)"
                >
                  <Th
                    padding={0}
                    flex="1"
                    color="var(--Text-Primary-description, #909090)"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="22px"
                    sx={{
                      textTransform: 'capitalize',
                      '&::first-letter': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Address
                  </Th>
                  <Th
                    padding="0px 12px"
                    flex="1"
                    color="var(--Text-Primary-description, #909090)"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="22px"
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      textTransform: 'capitalize',
                      '&::first-letter': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Balance
                    <BalanceFilter sort={balanceSort} onSort={handleBalanceSort} />
                  </Th>
                  <Th
                    padding={0}
                    flex="1"
                    display="flex"
                    color="var(--Text-Primary-description, #909090)"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                    justifyContent="space-between"
                    lineHeight="22px"
                    sx={{
                      textTransform: 'capitalize',
                      '&::first-letter': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Name
                    <FilterTag />
                  </Th>
                  <Th
                    padding={0}
                    flex="1"
                    display="flex"
                    justifyContent="flex-end"
                    color="var(--Text-Primary-description, #909090)"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="22px"
                    sx={{
                      textTransform: 'capitalize',
                      '&::first-letter': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Actions
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {tokenHolders.map((holder, index) => (
                  <Tr
                    key={holder.id || index}
                    display="flex"
                    alignItems="center"
                    padding="12px"
                    width={'791px'}
                    _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                    borderBottom="1px solid rgba(246, 246, 246, 0.8)"
                  >
                    <Td padding={0} flex="1" border="none">
                      <Text
                        padding={0}
                        color="var(--Text-action, #4096FF)"
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        cursor="pointer"
                        onClick={() => navigateToSinglePage(holder.wallet_address, holder.labels)}
                      >
                        {holder.wallet_address
                          ? `${holder.wallet_address.slice(0, 8)}...${holder.wallet_address.slice(-8)}`
                          : 'No Address'}
                      </Text>
                    </Td>
                    <Td padding={0} flex="1" border="none">
                      <Flex display="flex" alignItems="center" gap="8px">
                        <BalanceIcon fill="#39CA7F" />
                        <Text
                          color="var(--Text-Primary-default, #000)"
                          fontFamily="Poppins"
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="22px"
                          padding={0}
                        >
                          {calculateAdjustedBalance(holder.balance)}
                        </Text>
                      </Flex>
                    </Td>
                    <Td padding={0} flex="1" border="none">
                      <Flex
                        alignItems="center"
                        gap="4px"
                        borderRadius="4px"
                        cursor="pointer"
                        onClick={() => handleNameClick(holder)}
                      >
                        <TagIcon
                          fill={holder.name?.length > 0 ? '#4096FF' : 'var(--Text-Primary-description, #909090)'}
                        />
                        <Text
                          color={
                            holder.name?.length > 0
                              ? 'var(--Text-Primary-default, #000)'
                              : 'var(--Text-Primary-description, #909090)'
                          }
                          fontFamily="Poppins"
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="22px"
                          padding={0}
                        >
                          {holder.name?.length > 0 ? holder.name : '-'}
                        </Text>
                      </Flex>
                    </Td>{' '}
                    <Td flex="1" border="none" padding={0} display="flex" justifyContent="flex-end">
                      <Box
                        _hover={{
                          color: '#4096FF',
                        }}
                        cursor="pointer"
                        padding={0}
                        color="var(--Text-Primary-description, #909090)"
                      >
                        <ThreeDots
                          holder={holder}
                          onEdit={(record) => {
                            setSelectedHolder(record);
                            setIsAddingNew(false);
                            setIsShowSuccessModal(true);
                          }}
                          onDelete={(record) => {
                            setDeletingRecord(record);
                            setIsShowDeleteModal(true);
                          }}
                          onSaveChanges={handleSaveChanges}
                          onConfirmDelete={handleConfirmDelete}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Table variant="unstyled">
            <Thead>
              <Tr
                display="flex"
                height="40px"
                padding="0px 12px"
                alignItems="center"
                borderRadius="12px"
                background="var(--Neutrals-2, #F6F6F6)"
              >
                <Th
                  padding={0}
                  flex="1"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="22px"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Address
                </Th>
                <Th
                  padding="0px 12px"
                  flex="1"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="22px"
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Balance
                  <BalanceFilter sort={balanceSort} onSort={handleBalanceSort} />
                </Th>
                <Th
                  padding={0}
                  flex="1"
                  display="flex"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  justifyContent="space-between"
                  lineHeight="22px"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Name
                  <FilterTag />
                </Th>
                <Th
                  padding={0}
                  flex="1"
                  display="flex"
                  justifyContent="flex-end"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="22px"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Actions
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {tokenHolders.map((holder, index) => (
                <Tr
                  key={holder.id || index}
                  display="flex"
                  alignItems="center"
                  padding="12px"
                  _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                  borderBottom="1px solid rgba(246, 246, 246, 0.8)"
                >
                  <Td padding={0} flex="1" border="none">
                    <Text
                      padding={0}
                      color="var(--Text-action, #4096FF)"
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="22px"
                      cursor="pointer"
                      onClick={() => navigateToSinglePage(holder.wallet_address, holder.labels)}
                    >
                      {holder.wallet_address
                        ? `${holder.wallet_address.slice(0, 8)}...${holder.wallet_address.slice(-8)}`
                        : 'No Address'}
                    </Text>
                  </Td>
                  <Td padding={0} flex="1" border="none">
                    <Flex display="flex" alignItems="center" gap="8px">
                      <BalanceIcon fill="#39CA7F" />
                      <Text
                        color="var(--Text-Primary-default, #000)"
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        padding={0}
                      >
                        {calculateAdjustedBalance(holder.balance)}
                      </Text>
                    </Flex>
                  </Td>
                  <Td padding={0} flex="1" border="none">
                    <Flex
                      alignItems="center"
                      gap="4px"
                      borderRadius="4px"
                      cursor="pointer"
                      onClick={() => handleNameClick(holder)}
                    >
                      <TagIcon
                        fill={holder.name?.length > 0 ? '#4096FF' : 'var(--Text-Primary-description, #909090)'}
                      />
                      <Text
                        color={
                          holder.name?.length > 0
                            ? 'var(--Text-Primary-default, #000)'
                            : 'var(--Text-Primary-description, #909090)'
                        }
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        padding={0}
                      >
                        {holder.name?.length > 0 ? holder.name : '-'}
                      </Text>
                    </Flex>
                  </Td>{' '}
                  <Td flex="1" border="none" padding={0} display="flex" justifyContent="flex-end">
                    <Box
                      _hover={{
                        color: '#4096FF',
                      }}
                      cursor="pointer"
                      padding={0}
                      color="var(--Text-Primary-description, #909090)"
                    >
                      <ThreeDots
                        holder={holder}
                        onEdit={(record) => {
                          setSelectedHolder(record);
                          setIsAddingNew(false);
                          setIsShowSuccessModal(true);
                        }}
                        onDelete={(record) => {
                          setDeletingRecord(record);
                          setIsShowDeleteModal(true);
                        }}
                        onSaveChanges={handleSaveChanges}
                        onConfirmDelete={handleConfirmDelete}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {/* Pagination */}
        <TablePagination
          currentPage={pageIndex}
          totalCount={totalCount}
          onPageChange={(page) => {
            setPageIndex(page);
            localStorage.setItem('tokenHoldersPageIndex', page.toString());
          }}
          hasNext={pageIndex * pageSize < totalCount}
          hasPrevious={pageIndex > 1}
        />
      </Box>

      {/* Modals */}
      <AddressBookEditModal
        isShowModal={isShowSuccessModal}
        setIsShowModal={setIsShowSuccessModal}
        editingRecord={selectedHolder}
        onSaveChanges={handleSaveChanges}
        isAddingNew={isAddingNew}
      />

      <DeleteModal
        showModal={isShowDeleteModal}
        isShowModal={setIsShowDeleteModal}
        onConfirmDelete={handleConfirmDelete}
      />

      <Modal
        isOpen={isUpdating}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => setIsUpdating(false)}
      >
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" height="100%">
              <Text fontWeight="medium" textAlign="center" mb={4} color="#1f1f1f">
                Updating Address Book
              </Text>
              <Spinner size="xl" color="blue.500" />
              <Text fontSize="sm" textAlign="center" color="#1f1f1f" mt={4}>
                Please wait while we update your address book
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddressBook;

import { Box, Button, FormControl, Image, Input, InputGroup, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import CoveyBlack from '../../assets/images/svgs/CoveyBlack.svg';
import CoveyLogoText from '../../assets/images/svgs/CoveyText.svg';
import Text from '../../components/common/Text';
import { PasswordField } from '../../components/logIn/loginForm/PasswordField';

const SignupMain = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
    },
  });

  const handleFormSubmit = async (data: { email: string; password: string }) => {
    // if (!isAgreed) return;

    if (password !== confirmPassword) {
      toast({
        title: 'Error',
        description: 'Passwords do not match',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    axios
      .post('/api/user/account/create/', data)
      .then((response) => {
        console.log(response);
        if (response) {
          toast({
            title: 'Success',
            description: 'User registered successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          axios
            .post('/api/user/login/', data)
            .then((loginResponse) => {
              if (loginResponse.status === 200) {
                console.log(loginResponse.data.data, 'response.data.data');
                localStorage.setItem('access_token', loginResponse.data.data.access_token);
                localStorage.setItem('refresh_token', loginResponse.data.data.refresh_token);
                navigate('/step-1');
              }
            })
            .catch(() => {
              toast({
                title: 'Login error',
                description: 'Something went wrong while logging in',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            });
        }
      })
      .catch(() => {
        toast({
          title: 'Signup error',
          description: 'Something went wrong while registering user',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };
  const handleCheckboxClick = () => {
    setIsAgreed(!isAgreed);
  };

  return (
    <Box
      background="var(--Neutrals-1, #FFF)"
      display="flex"
      width="100%"
      height="100vh"
      paddingTop="100px"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        display="flex"
        width={['100%', '100%', 'fit-content', '410px']}
        flexDirection="column"
        alignItems="center"
        gap="36px"
        padding={['0px 12px', '0px 24px', '0px']}
        borderRadius="10px"
      >
        <Box display={'flex'} gap="10px">
          <Image src={CoveyBlack} width={'38.276px'} height={'31.406px'} />
          <Image src={CoveyLogoText} />
        </Box>
        <Box
          display="flex"
          minWidth="310px"
          flexDirection="column"
          alignItems="flex-start"
          gap="36px"
          alignSelf="stretch"
          borderRadius="16px"
        >
          <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
            <Text fs="28px" textAlign={'center'}>
              SignUp
            </Text>
            <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight={'22px'}>
              Already have an account?
              <span onClick={() => navigate('/')} style={{ color: 'var(--Text-action, #4096FF)', cursor: 'pointer' }}>
                Login
              </span>
            </Text>
          </Box>
          <FormControl display="flex" flexDirection="column" alignItems="flex-start" gap="20px" alignSelf="stretch">
            <InputGroup display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
              <Text c="var(--Neutrals-500, #8A8A8A)" fs="14px" lineHeight={'16px'} fw={400} textAlign={'left'}>
                Email{' '}
                <span
                  style={{
                    color: 'var(--Red-500, #E35A5A)',

                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '16px',
                  }}
                >
                  *
                </span>
              </Text>
              <Input
                {...register('email', { required: true })}
                placeholder="Enter email"
                display="flex"
                height="48px"
                padding="8px 13px"
                alignItems="center"
                fontFamily={'Poppins'}
                _placeholder={{
                  color: 'var(--Neutrals-300, #C2C2C2)',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
                gap="10px"
                alignSelf="stretch"
                borderRadius="14px"
                border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                background="var(--Neutrals-100, #FAFAFA)"
              />
            </InputGroup>

            <PasswordField
              {...register('password', { required: true })}
              password={password}
              setPassword={setPassword}
              isRequired={true}
            />
            <PasswordField
              {...register('confirm_password', { required: true })}
              password={confirmPassword}
              setPassword={setConfirmPassword}
              placeholder="Enter password again"
              isRequired={true}
              label="Re-Type Password"
            />
          </FormControl>
        </Box>
        {/* <Box
          display="flex"
          alignItems="left"
          gap="var(--Checkbox-marginXS, 8px)"
          width={'100%'}
          onClick={handleCheckboxClick}
          cursor="pointer"
        >
          <Check isSelected={isAgreed} />
          <Text c="rgba(0, 0, 0, 0.88)" fs="14px" fw={500} lineHeight={'22px'}>
            I agree to the{' '}
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.88)',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '22px',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              Terms and Conditions{' '}
            </span>
          </Text>
        </Box> */}
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          display="flex"
          alignItems="center"
          gap="var(--Checkbox-marginXS, 8px)"
          borderRadius="16px"
          border="1px solid #2D8DFB"
          background="#2D8DFB"
          _hover={{ background: '#2D8DFB' }}
          // opacity={isAgreed ? 1 : 0.5}
          // _hover={{ background: '#2D8DFB', opacity: isAgreed ? 0.9 : 0.5 }}
          color="var(--Button-colorTextLightSolid, #FFF)"
          fontFamily="Poppins"
          fontSize="14px"
          fontStyle="normal"
          fontWeight="500"
          width={'100%'}
          lineHeight="22px"
          // cursor={isAgreed ? 'pointer' : 'not-allowed'}
        >
          <span>Sign up</span>
        </Button>
      </Box>
    </Box>
  );
};

export default SignupMain;

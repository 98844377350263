import { Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Line, LineChart, Tooltip as RechartsTooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import ArrowD from '../../assets/images/svgs/ArrowD.svg';
import ArrowUp from '../../assets/images/svgs/ArrowUp.svg';
import Info from '../../assets/images/svgs/Info.svg';
import Refresh from '../../assets/images/svgs/Refresh.svg';
import { PriceChartProps } from '../../types/Types';
import Text from '../common/Text';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<any>;
  label?: string;
  isHolderChart?: boolean;
}

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: value < 1 ? 8 : 2,
    maximumFractionDigits: value < 1 ? 8 : 2,
  }).format(value);
};

const formatNumber = (value: number): string => {
  return new Intl.NumberFormat('en-US').format(value);
};

const formatDate = (timestamp: string) => {
  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return new Date().toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    }
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  } catch (error) {
    return new Date().toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
};

const formatLastUpdated = (apiTime: string | null | undefined, isSecondChart: boolean) => {
  if (!apiTime) return '';

  if (isSecondChart) {
    return apiTime;
  }

  const today = new Date();
  const currentDate = today.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return `${currentDate}, ${apiTime}`;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, isHolderChart }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        bg="white"
        p="12px"
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      >
        <Box borderBottom="1px solid rgba(0, 0, 0, 0.1)" pb="8px" mb="8px">
          <Text fontSize="12px" color="#666" mb="4px">
            Date
          </Text>
          <Text fontSize="14px" fontWeight="600" color="#000">
            {formatDate(label || '')}
          </Text>
        </Box>
        <Box>
          <Text fontSize="12px" color="#666" mb="4px">
            {isHolderChart ? 'Holders' : 'Price'}
          </Text>
          <Text fontSize="14px" fontWeight="600" color="#000">
            {isHolderChart ? formatNumber(payload[0].value) : formatCurrency(payload[0].value)}
          </Text>
        </Box>
      </Box>
    );
  }
  return null;
};

interface ExtendedPriceChartProps extends PriceChartProps {
  infoTooltip?: string;
}

const PriceChart: React.FC<ExtendedPriceChartProps> = ({
  config,
  priceData,
  holderData,
  infoTooltip = 'Chart Information',
}) => {
  const chartData = config.isSecondChart
    ? holderData?.chart_data?.map((item) => ({
        timestamp: item.date,
        value: item.holder_count,
        percent_change: item.daily_percent_change,
      })) || []
    : priceData?.charts?.price?.data || [];

  const currentValue = config.isSecondChart
    ? holderData?.current_stats?.holder_count
    : priceData?.current_stats?.price?.value;

  const changePercentage = config.isSecondChart
    ? holderData?.current_stats?.change_7d
    : priceData?.current_stats?.price?.change_7d;

  const lastUpdated = config.isSecondChart
    ? formatLastUpdated(holderData?.current_stats?.last_updated, true)
    : formatLastUpdated(priceData?.current_stats?.price?.last_updated, false);

  const colorVariables = {
    green: 'var(--green-5, #39CA7F)',
    red: 'var(--red-5, #FF4D4F)',
  };

  const isPositiveChange = !config.isSecondChart && (changePercentage || 0) >= 0;
  const chartColor = config.isSecondChart
    ? colorVariables.red
    : isPositiveChange
    ? colorVariables.green
    : colorVariables.red;

  const formattedChartData = chartData.map((item) => ({
    ...item,
    formattedDate: formatDate(item.timestamp || ''),
    value: typeof item.value === 'number' ? item.value : 0,
  }));

  return (
    <Box
      display="flex"
      padding={['24px', '24px', '12px', '24px']}
      alignItems="flex-start"
      flexDirection={['column', 'row', 'column', 'column', 'column', 'row']}
      gap="16px"
      flex="1 0 0"
      alignSelf="stretch"
      flexWrap="wrap"
      borderRadius={['24px', '24px', '24px', '36px']}
      border={[
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(255, 255, 255, 0.10)',
      ]}
      background="var(--Neutrals-1, #FFF)"
      boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
    >
      <Flex
        display="flex"
        height={['fit-content', 'fit-content', 'fit-content', '136px']}
        minWidth="240px"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="auto"
        flex="1 0 0"
      >
        <Flex alignItems="center" gap="8px">
          <Tooltip
            label={infoTooltip}
            placement="top"
            hasArrow
            bg="white"
            color="black"
            p="8px 12px"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            fontSize="14px"
          >
            <Box as="span" cursor="pointer">
              <Image src={Info} width="16px" height="16px" />
            </Box>
          </Tooltip>
          <Text fontSize="16px" fontWeight="600" color="black">
            {config.title}
          </Text>
        </Flex>

        <Flex
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          gap={['5px', '5px', '5px', '8px']}
          alignSelf="stretch"
        >
          <Text
            fs={['32px', '32px', '32px', '42px', '42px', '42px']}
            fontWeight="600"
            letterSpacing="-1.2px"
            lineHeight="52px"
          >
            {config.isSecondChart
              ? formatNumber(currentValue || 0)
              : currentValue
              ? formatCurrency(currentValue)
              : '$0.00'}
          </Text>

          <Flex display="flex" alignItems="center" gap="8px">
            <Image src={Refresh} width="16px" height="16px" alignSelf={'flex-start'} />
            <Text color="var(--Neutrals-6, #909090)" fontSize="14px" fontWeight="500">
              Last updated {lastUpdated}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Box
        display="flex"
        height="136px"
        minWidth="190px"
        flexDirection="column"
        alignItems="flex-start"
        gap="var(--stepsCurrentDotSize, 10px)"
        flex="1 0 0"
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={formattedChartData} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
            <XAxis dataKey="formattedDate" hide={true} />
            <YAxis hide={true} domain={['auto', 'auto']} />
            <RechartsTooltip
              content={<CustomTooltip isHolderChart={config.isSecondChart} />}
              cursor={{ stroke: 'rgba(0, 0, 0, 0.1)', strokeWidth: 1 }}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke={chartColor}
              strokeWidth={3}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
        <Box display="flex" alignItems="center" gap="8px">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Image
              src={config.isSecondChart ? ArrowD : isPositiveChange ? ArrowUp : ArrowD}
              alt={isPositiveChange ? 'up arrow' : 'down arrow'}
            />
            <Text fs="14px" fw={500} c={chartColor}>
              {Math.abs(changePercentage || 0).toFixed(2)}%
            </Text>
          </Box>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500}>
            vs last 7 days
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PriceChart;

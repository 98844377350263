import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

interface CheckProps extends IconProps {
  isSelected?: boolean;
}

export const Check = ({ isSelected, ...props }: CheckProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
    <rect width="16" height="16" rx="4" fill={isSelected ? '#4096FF' : 'white'} />
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="3.5"
      stroke={isSelected ? '#4096FF' : 'black'}
      strokeOpacity={isSelected ? '1' : '0.15'}
    />
    {isSelected && (
      <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    )}
  </Icon>
);

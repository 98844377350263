import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import ResetBox from '../../../../../assets/images/svgs/ResetBox.svg';
import Text from '../../../../common/Text';

export const PassStep2: React.FC = () => {
  const location = useLocation();
  const email = location.state?.email || 'your email';

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      paddingTop="100px"
      flexDirection="column"
      alignItems="center"
      width={'100%'}
      bg="var(--Neutrals-1, #FFF)"
    >
      <Box
        display="flex"
        padding={['0px 12px', '0px 24px', '0px']}
        width={['100%', '100%', 'fit-content', '410px']}
        flexDirection="column"
        alignItems="center"
        gap="36px"
        borderRadius="16px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs={'28px'}>Check your mail to continue</Text>
          <Text
            fs={'14px'}
            fw={500}
            lineHeight={'22px'}
            c="var(--Text-Primary-description, #909090)"
            textAlign={'center'}
          >
            A password reset link has been sent to <br />{' '}
            <span style={{ color: 'var(--Text-action, #4096FF)' }}>{email}</span>
          </Text>
        </Box>
        <Box
          width={'64px'}
          height={'64px'}
          display="flex"
          borderRadius="64px"
          bg="var(--blue-1, #E6F4FF)"
          justifyContent={'center'}
        >
          <Image src={ResetBox} alt="check" />
        </Box>
        <Text
          fs={'14px'}
          fw={500}
          lineHeight={'22px'}
          c="var(--Text-Primary-description, #909090)"
          textAlign={'center'}
        >
          Didn&apos;t receive email? <span style={{ color: 'var(--Text-action, #4096FF)' }}> Resend link</span>
        </Text>
      </Box>
    </Box>
  );
};

export default PassStep2;

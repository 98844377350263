import { Box, Button, FormControl, Input, InputGroup, useToast } from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../src/components/common/CustomSelect';
import axios from '../../api/axios';
import Text from '../common/Text';

const Step1 = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      token_address: '',
    },
  });

  useEffect(() => {
    fetchNetworks();
  }, []);

  const fetchNetworks = async () => {
    try {
      const response = await axios.get('/api/user/blockchain-networks/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setNetworks(response.data);
    } catch (error) {
      console.error('Error fetching networks:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch blockchain networks',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleNetworkSelect = (value: string) => {
    setSelectedNetwork(value);
  };

  const handleFormSubmit = async (data: any) => {
    if (!selectedNetwork) {
      toast({
        title: 'Error',
        description: 'Please select a blockchain network',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.patch(
        '/api/user/account/',
        {
          blockchain_network: selectedNetwork,
          token_address: data.token_address,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      await axios.post(
        '/api/user/coinmarketcap-info/',
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      const tokenAddressData = {
        token_address: data.token_address,
      };

      const tokenResponse = await axios.post('/api/user/update-token-address/', tokenAddressData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      if (tokenResponse.status === 202) {
        toast({
          title: 'Success',
          description: 'Blockchain network and token address successfully updated',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/step-2');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      toast({
        title: 'Error',
        description: 'Failed to update user data',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" width="100%" height="100vh" pt="100px" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        padding={['0px 12px', '0px 24px', '0px']}
        flexDirection="column"
        width={['100%', '100%', 'fit-content', '410px']}
        alignItems="center"
        gap="56px"
        borderRadius="10px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs="20px">A few more steps</Text>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
            You can complete this later, feel free to skip
          </Text>
          <Box
            width={'100%'}
            gap={'6px'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginTop={'24px'}
          >
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#4096FF"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px"
              color={'var(--Neutrals-1, #FFF)'}
            >
              1
            </Box>
            <Box width={'30%'} height={'6px'} background={'#f4f4f4'} flex={1}></Box>
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#f4f4f4"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px"
              color={'var(--Text-Primary-description, #909090)'}
            >
              2
            </Box>
            <Box width={'30%'} height={'6px'} background={'#f4f4f4'} flex={1}></Box>
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#f4f4f4"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              color={'var(--Text-Primary-description, #909090)'}
              lineHeight="36px"
            >
              3
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs="28px" lineHeight={'32px'}>
            Provide token address
          </Text>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
            You can complete this later, feel free to skip
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="28px" alignSelf="stretch">
          <FormControl display="flex" flexDirection="column" alignItems="flex-start" gap="20px" alignSelf="stretch">
            <Box width={'100%'} gap={'8px'} display={'flex'} flexDirection={'column'}>
              <Text c="var(--Neutrals-500, #8A8A8A)" fs={'12px'} fw={400} lineHeight={'16px'} textAlign={'left'}>
                Select network
              </Text>
              {/* <Select
                style={{
                  width: '100%',
                  display: 'flex',
                  padding: '10px 12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '6px',
                  height: 'fit-content',
                  alignSelf: 'stretch',
                  borderRadius: '14px',
                  border: '1px solid #DEDEDE',
                }}
                placeholder="Select a blockchain network"
                onChange={handleNetworkSelect}
                value={selectedNetwork}
                options={networks.map((network: any) => ({
                  value: network.id.toString(),
                  label: (
                    <Box display="flex" alignItems="center" gap="8px">
                      <Image src={network.logo} />
                      <span>{network.network_name}</span>
                    </Box>
                  ),
                }))}
              /> */}
              <CustomSelect
                networks={networks}
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={setSelectedNetwork}
              />
            </Box>
            <InputGroup display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
              <Text c="var(--Neutrals-500, #8A8A8A) " fs="14px" lineHeight={'16px'} fw={400} textAlign={'left'}>
                Token address
              </Text>
              <Input
                placeholder="Enter token address"
                {...register('token_address', {
                  required: 'Token address is required',
                })}
                _placeholder={{
                  color: 'var(--Neutrals-300, #C2C2C2)',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
                fontFamily={'Poppins'}
                display="flex"
                height="48px"
                padding="8px 13px"
                alignItems="center"
                gap="10px"
                alignSelf="stretch"
                borderRadius="14px"
                border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                background="var(--Neutrals-100, #FAFAFA);"
              />
            </InputGroup>
          </FormControl>
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="16px" alignSelf="stretch">
            <Button
              onClick={handleSubmit(handleFormSubmit)}
              display="flex"
              alignItems="center"
              gap="var(--Checkbox-marginXS, 8px)"
              borderRadius="16px"
              border="1px solid #2D8DFB"
              background="#2D8DFB"
              _hover={{ background: '#2D8DFB' }}
              color="var(--Button-colorTextLightSolid, #FFF)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              width={'100%'}
              lineHeight="22px"
            >
              <span>Next</span>
            </Button>
            <Button
              onClick={() => navigate('/step-2')}
              display="flex"
              padding="6px var(--Button-paddingContentHorizontal, 16px)"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              alignSelf="stretch"
              borderRadius="16px"
              border="1px solid rgba(0, 0, 0, 0.15)"
              color="rgba(0, 0, 0, 0.88)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="22px"
              background="var(--Button-colorBgContainer, #FFF)"
            >
              <span>Skip</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step1;

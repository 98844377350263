import { Box, Image, useOutsideClick } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import ArrowSignup from '../../assets/images/svgs/ArrowSignup.svg';
import Text from './Text';

interface Network {
  id: string;
  network_name: string;
  logo: string;
}

interface CustomSelectProps {
  networks: Network[];
  selectedNetwork: string | null;
  setSelectedNetwork: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ networks, selectedNetwork, setSelectedNetwork }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });

  const selectedItem = networks.find((n) => n.id.toString() === selectedNetwork);

  return (
    <Box position="relative" ref={ref} width="100%">
      <Box
        onClick={() => setIsOpen(!isOpen)}
        display="flex"
        padding="10px 12px"
        alignItems="center"
        justifyContent={'space-between'}
        cursor="pointer"
        borderRadius="14px"
        border="1px solid #DEDEDE"
        bg="white"
        width="100%"
      >
        {selectedItem ? (
          <>
            <Box width={'100%'} display={'flex'} alignItems="center" gap={'8px'}>
              <Image src={selectedItem.logo} boxSize="20px" objectFit="contain" />
              <Text c="#000000" fs="14px">
                {selectedItem.network_name}
              </Text>
            </Box>
            <Image
              src={ArrowSignup}
              transform={isOpen ? 'rotate(180deg)' : 'none'}
              transition="transform 0.2s ease"
              filter={
                isOpen
                  ? 'invert(37%) sepia(74%) saturate(1045%) hue-rotate(191deg) brightness(91%) contrast(107%)'
                  : 'none'
              }
            />
          </>
        ) : (
          <Box display={'flex'} alignItems="center" justifyContent={'space-between'} width={'100%'}>
            <Text c="var(--Neutrals-300, #C2C2C2)" fs="14px" fw={400} lineHeight={'20px'}>
              Select a blockchain network
            </Text>
            <Image
              src={ArrowSignup}
              transform={isOpen ? 'rotate(180deg)' : 'none'}
              transition="transform 0.2s ease"
              filter={
                isOpen
                  ? 'invert(37%) sepia(74%) saturate(1045%) hue-rotate(191deg) brightness(91%) contrast(107%)'
                  : 'none'
              }
            />
          </Box>
        )}
      </Box>

      {isOpen && (
        <Box
          position="absolute"
          top="calc(100% + 4px)"
          left="0"
          width="100%"
          bg="white"
          borderRadius="14px"
          boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
          border="1px solid #DEDEDE"
          zIndex={10}
          maxH="300px"
          overflowY="auto"
        >
          {networks.map((network) => (
            <Box
              key={network.id}
              display="flex"
              alignItems="center"
              gap="8px"
              padding="10px 12px"
              cursor="pointer"
              _hover={{ bg: '#f5f5f5' }}
              onClick={() => {
                setSelectedNetwork(network.id.toString());
                setIsOpen(false);
              }}
            >
              <Image src={network.logo} boxSize="20px" objectFit="contain" />
              <Text color="var(--Text-Alt, #000)" fs="14px">
                {network.network_name}
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CustomSelect;

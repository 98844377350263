// //
// import { Box, Flex, Image, Input, InputGroup, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
// import React, { useMemo, useState } from 'react';
// import Arrow from '../../src/assets/images/svgs/arrow-swap-horizontal.svg';
// import BackIcon from '../../src/assets/images/svgs/BackArrow.svg';
// import Copy from '../../src/assets/images/svgs/copy.svg';
// import ExternalLink from '../../src/assets/images/svgs/ExternalLink.svg';
// import Info from '../../src/assets/images/svgs/Info.svg';
// import PaginationItem from '../../src/assets/images/svgs/PaginationItem.svg';
// import SearchIcon from '../../src/assets/images/svgs/SearchIcon.svg';
// import AssestComp from '../components/common/AssestComp';
// import DatePicker from '../components/common/DatePicker';
// import SendReceiveBlock from '../components/common/SendReceiveBlock';
// import Text from '../components/common/Text';
// import { BalanceIcon } from '../Icons/BalanceIcon';
// import { FilterTag } from '../Icons/FilterTag';
// import { TagIcon } from '../Icons/TagIcon';
// import TablePagination from './TablePagination';
// const AllTransactions = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currencyFilter, setCurrencyFilter] = useState('all');
//   const ITEMS_PER_PAGE = 10;

//   const filteredAndPaginatedData = useMemo(() => {
//     let filtered = mockTransactions;

//     // Apply search filter
//     if (searchTerm) {
//       filtered = filtered.filter(
//         (tx) =>
//           tx.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           tx.to.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           tx.currency.toLowerCase().includes(searchTerm.toLowerCase()),
//       );
//     }

//     // Apply currency filter
//     if (currencyFilter !== 'all') {
//       filtered = filtered.filter((tx) => tx.currency === currencyFilter);
//     }

//     // Calculate pagination
//     const totalPages = Math.ceil(filtered.length / ITEMS_PER_PAGE);
//     const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
//     const paginatedData = filtered.slice(startIndex, startIndex + ITEMS_PER_PAGE);

//     return {
//       data: paginatedData,
//       totalCount: filtered.length,
//       hasNext: currentPage < totalPages,
//       hasPrevious: currentPage > 1,
//     };
//   }, [searchTerm, currencyFilter, currentPage]);

//   // Handlers
//   const handlePageChange = (page: number) => setCurrentPage(page);
//   const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1); // Reset to first page on search
//   };
//   const handleCurrencyFilter = () => {
//     setCurrencyFilter(currencyFilter === 'all' ? 'Drink' : 'all');
//     setCurrentPage(1); // Reset to first page on filter
//   };

//   return (
//     <Box
//       display="flex"
//       padding="14px 24px 40px 24px"
//       flexDirection="column"
//       alignItems="flex-start"
//       gap="36px"
//       flex="1 0 0"
//       alignSelf="stretch"
//     >
//       <Box
//         display="flex"
//         padding="6px var(--Button-paddingContentHorizontal, 16px)"
//         justifyContent="center"
//         alignItems="center"
//         gap="8px"
//         borderRadius="16px"
//         border="1px solid rgba(0, 0, 0, 0.15)"
//         background="var(--Button-colorBgContainer, #FFF)"
//       >
//         <Box
//           display="flex"
//           height="var(--Button-controlHeight, 32px)"
//           justifyContent="center"
//           alignItems="center"
//           gap="var(--Button-marginXS, 8px)"
//         >
//           <Image src={BackIcon} />
//           <Text fs="14px" fw={500} lineHeight={'22px'}>
//             Back to list
//           </Text>
//         </Box>
//       </Box>

//       <Box
//         display="flex"
//         flexDirection="column"
//         justifyContent="flex-end"
//         alignItems="flex-start"
//         gap="16px"
//         alignSelf="stretch"
//       >
//         <Text fs="36px" lineHeight={'28px'} c="var(--Text-Primary-default, #000)">
//           Wallet Overview
//         </Text>
//         <Text fs="14px" fw={400} lineHeight="22px" c="var(--Text-Primary-default, #000)">
//           Explore wallet assets, transaction history, and more in one place.”
//         </Text>
//       </Box>
//       <Box display="flex" alignItems="flex-start" gap="14px" alignSelf="stretch">
//         <Box
//           display="flex"
//           padding="24px"
//           flexDirection="column"
//           alignItems="flex-start"
//           gap="16px"
//           flex="1 0 0"
//           alignSelf="stretch"
//           borderRadius="36px"
//           border="1px solid rgba(255, 255, 255, 0.10)"
//           background="var(--Neutrals-1, #FFF)"
//           boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
//         >
//           <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch">
//             <Box display="flex" width="97px" justifyContent="space-between" alignItems="center">
//               <Image src={Info} />
//               <Text fs="16px" lineHeight="28px">
//                 Balance
//               </Text>
//             </Box>
//             <Box
//               display="flex"
//               padding="6px 12px 6px 6px"
//               justifyContent="center"
//               alignItems="center"
//               gap="6px"
//               borderRadius="10px"
//               border="1px solid rgba(0, 0, 0, 0.05)"
//               background="#FFF"
//             >
//               <TagIcon fill={'#4096FF'} />
//               <Text fs="16px" lineHeight="28px">
//                 Investor
//               </Text>
//             </Box>
//           </Box>
//           <Box
//             display="flex"
//             padding="16px"
//             flexDirection="column"
//             alignItems="flex-start"
//             gap="16px"
//             alignSelf="stretch"
//             background={'var(--Neutrals-2, #F6F6F6)'}
//             borderRadius={'18px'}
//           >
//             {/* Inner Box */}
//             <Box display="flex" alignItems="center" gap="10px">
//               <BalanceIcon fill={'#39CA7F'} width={'36px'} height={'36px'} />
//               <Text fs="36px" lineHeight={'52px'} letterSpacing={'-1.08px'}>
//                 28478
//               </Text>
//             </Box>
//             <Box
//               display="flex"
//               padding="10px 10px 10px 12px"
//               justifyContent="center"
//               alignItems="center"
//               gap="6px"
//               borderRadius="10px"
//               background="var(--Neutrals-1, #FFF)"
//               boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.19)"
//             >
//               <Text
//                 fs="14px"
//                 fw={500}
//                 lineHeight={'18px'}
//                 paddingRight={'6px'}
//                 borderRight={' 1px solid rgba(0, 0, 0, 0.10)'}
//               >
//                 0xA8ceE...Cc8cE
//               </Text>
//               <Image src={Copy} />
//             </Box>
//           </Box>
//           <Box
//             display="flex"
//             padding="16px"
//             gap="auto"
//             flexDirection="column"
//             justifyContent="space-between"
//             alignItems="flex-start"
//             flex="1 0 0"
//             alignSelf="stretch"
//             borderRadius="18px"
//             border="1px dashed rgba(0, 0, 0, 0.10)"
//             background="var(--Neutrals-1, #FFF)"
//             boxShadow="0px 9px 28px 8px rgba(0, 0, 0, 0.05),
//                  0px 3px 6px -4px rgba(0, 0, 0, 0.12),
//                  0px 6px 16px 0px rgba(0, 0, 0, 0.08)"
//           >
//             <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch">
//               <Text fs="16px" lineHeight={'28px'}>
//                 Last Transcation
//               </Text>
//               <SendReceiveBlock type="receive" />
//             </Box>
//             <Box display="flex" alignItems="center" gap="10px">
//               <BalanceIcon fill={'#39CA7F'} width={'20px'} height={'20px'} />
//               <Box display="flex" alignItems="flex-end" gap="4px">
//                 <Text fs="20px" lineHeight={'20px'} letterSpacing={'0.6px'}>
//                   28478
//                 </Text>
//                 <Text
//                   fs="14px"
//                   lineHeight={'16px'}
//                   letterSpacing={'-0.42px'}
//                   fw={500}
//                   c="var(--Text-Primary-description, #909090)"
//                 >
//                   $DRINK
//                 </Text>
//               </Box>
//             </Box>
//             <Box
//               display="flex"
//               padding="12px"
//               flexDirection="column"
//               alignItems="flex-start"
//               gap="12px"
//               alignSelf="stretch"
//               borderRadius="10px"
//               background="var(--Neutrals-2, #F6F6F6)"
//             >
//               <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch">
//                 <Text fs="14px" lineHeight={'18px'} fw={500}>
//                   Today, 5:44:17 PM
//                 </Text>
//                 <Box display="flex" alignItems="flex-start" gap={'8px'}>
//                   <Text fs="14px" lineHeight={'18px'} fw={500}>
//                     0xadc3...msa
//                   </Text>
//                   <Image src={ExternalLink} />
//                 </Box>
//               </Box>

//               {/* Second Inner Box */}
//               <Box
//                 display="flex"
//                 paddingTop="10px"
//                 justifyContent="space-between"
//                 alignItems="center"
//                 alignSelf="stretch"
//                 borderTop="1px dashed rgba(0, 0, 0, 0.10)"
//               >
//                 <Text fs="14px" lineHeight={'18px'} fw={500}>
//                   0xvkc...d4zcd
//                 </Text>
//                 <Image src={Arrow} />
//                 <Text fs="14px" lineHeight={'18px'} fw={500}>
//                   0xAvc...Cc8cE
//                 </Text>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//         <Box
//           display="flex"
//           padding="24px"
//           flexDirection="column"
//           alignItems="flex-end"
//           gap="16px"
//           flex="1 0 0"
//           borderRadius="36px"
//           border="1px solid rgba(255, 255, 255, 0.10)"
//           background="var(--Neutrals-1, #FFF)"
//           boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
//         >
//           <Text fs="16px" lineHeight={'28px'} textAlign={'left'} width={'100%'}>
//             Assets
//           </Text>
//           <AssestComp />
//           <Image src={PaginationItem} />
//         </Box>
//       </Box>
//       <Box
//         display="flex"
//         padding="24px"
//         flexDirection="column"
//         alignItems="center"
//         gap="24px"
//         alignSelf="stretch"
//         borderRadius="36px"
//         border="1px solid rgba(255, 255, 255, 0.10)"
//         background="var(--Neutrals-1, #FFF)"
//         boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
//       >
//         <Flex justifyContent={'space-between'} width={'100%'} flex={'1 0 0'} alignItems={'center'}>
//           <Text fs="20px" lineHeight={'28px'}>
//             Transactions
//           </Text>

//           {/* Search and Add Section */}
//           <Flex gap={'12px'} justify="space-between" align="center">
//             <DatePicker />
//             <InputGroup
//               width="fit-content"
//               padding="0px 20px 0px 12px"
//               gap="10px"
//               border="0.5px solid var(--Neutrals-4, #E9E9E9)"
//               borderRadius="16px"
//               background="var(--Neutrals-2, #F6F6F6)"
//               alignItems="center"
//             >
//               <Image src={SearchIcon} alt="search" cursor="pointer" />
//               <Input
//                 border="none"
//                 color="#000"
//                 padding={0}
//                 placeholder="Search by address or tag"
//                 background="transparent"
//                 _placeholder={{
//                   color: 'var(--Text-Primary-description, #909090)',
//                   fontFamily: 'Poppins',
//                   fontSize: '14px',
//                   fontWeight: '500',
//                 }}
//                 _focus={{
//                   border: 'none',
//                   boxShadow: 'none',
//                 }}
//               />
//             </InputGroup>
//           </Flex>
//         </Flex>
//         {/* Table */}
//         <Table variant="unstyled">
//           <Thead>
//             <Tr
//               display="flex"
//               height="40px"
//               padding="0px 12px"
//               alignItems="center"
//               borderRadius="12px"
//               background="var(--Neutrals-2, #F6F6F6)"
//             >
//               <Th
//                 padding={0}
//                 flex="1"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 lineHeight="22px"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 From
//               </Th>
//               <Th
//                 padding="0px"
//                 paddingRight={'12px'}
//                 flex="1"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 lineHeight="22px"
//                 alignItems="center"
//                 display="flex"
//                 justifyContent="space-between"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 To
//               </Th>
//               <Th
//                 padding="0px"
//                 paddingRight={'12px'}
//                 flex="1"
//                 display="flex"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 justifyContent="space-between"
//                 lineHeight="22px"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 Amount
//               </Th>
//               <Th
//                 padding="0px"
//                 paddingRight={'12px'}
//                 flex="1"
//                 display="flex"
//                 justifyContent="space-between"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 lineHeight="22px"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 Type
//               </Th>
//               <Th
//                 padding="0px"
//                 paddingRight={'12px'}
//                 flex="1"
//                 display="flex"
//                 justifyContent="space-between"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 lineHeight="22px"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 Currency
//                 <FilterTag />
//               </Th>
//               <Th
//                 padding="0px"
//                 paddingRight={'12px'}
//                 flex="1"
//                 display="flex"
//                 justifyContent="space-between"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 lineHeight="22px"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 Tx Hash
//               </Th>
//               <Th
//                 padding="0px"
//                 paddingRight={'12px'}
//                 flex="1"
//                 display="flex"
//                 justifyContent="space-between"
//                 color="var(--Text-Primary-description, #909090)"
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 fontWeight="500"
//                 lineHeight="22px"
//                 sx={{
//                   textTransform: 'capitalize',
//                   '&::first-letter': {
//                     fontSize: '14px',
//                   },
//                 }}
//               >
//                 Block Time
//               </Th>
//             </Tr>
//           </Thead>

//           <Tbody>
//             {filteredAndPaginatedData.data.map((transaction) => (
//               <Tr
//                 key={transaction.id}
//                 display="flex"
//                 alignItems="center"
//                 padding="12px"
//                 _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
//                 borderBottom="1px solid rgba(246, 246, 246, 0.8)"
//               >
//                 <Td padding="0px" flex="1" border="none">
//                   <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
//                     {transaction.from}
//                   </Text>
//                 </Td>
//                 <Td padding="0px" paddingRight={'12px'} flex="1" border="none">
//                   <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
//                     {transaction.to}
//                   </Text>
//                 </Td>
//                 <Td padding="0px" paddingRight={'12px'} flex="1" border="none">
//                   <Flex display="flex" alignItems="center" gap="8px">
//                     <BalanceIcon fill="#39CA7F" />
//                     <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
//                       {transaction.amount.toFixed(2)}
//                     </Text>
//                   </Flex>
//                 </Td>
//                 <Td padding="0px" paddingRight={'12px'} flex="1" border="none">
//                   <Flex alignItems="center" gap="4px" borderRadius="4px" cursor="pointer">
//                     <SendReceiveBlock type={transaction.type} />
//                   </Flex>
//                 </Td>
//                 <Td
//                   flex="1"
//                   border="none"
//                   padding="0px"
//                   paddingRight={'12px'}
//                   display="flex"
//                   justifyContent="space-between"
//                 >
//                   <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
//                     {transaction.currency}
//                   </Text>
//                 </Td>
//                 <Td
//                   flex="1"
//                   border="none"
//                   padding="0px"
//                   paddingRight={'12px'}
//                   display="flex"
//                   justifyContent="space-between"
//                 >
//                   <Text
//                     fs="14px"
//                     fw={500}
//                     lineHeight="22px"
//                     padding={0}
//                     c="var(--Text-action, #4096FF)"
//                     textAlign={'left'}
//                   >
//                     {transaction.txHash}
//                   </Text>
//                 </Td>
//                 <Td
//                   flex="1"
//                   border="none"
//                   padding="0px"
//                   paddingRight={'12px'}
//                   display="flex"
//                   justifyContent="space-between"
//                 >
//                   <Text
//                     fs="14px"
//                     fw={500}
//                     lineHeight="22px"
//                     padding={0}
//                     c="var(--Text-action, #4096FF)"
//                     textAlign={'left'}
//                   >
//                     {transaction.blockTime}
//                   </Text>
//                 </Td>
//               </Tr>
//             ))}
//           </Tbody>
//         </Table>

//         <TablePagination
//           currentPage={currentPage}
//           totalCount={filteredAndPaginatedData.totalCount}
//           entriesPerPage={ITEMS_PER_PAGE}
//           onPageChange={handlePageChange}
//           hasNext={filteredAndPaginatedData.hasNext}
//           hasPrevious={filteredAndPaginatedData.hasPrevious}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default AllTransactions;

// const mockTransactions = [
//   {
//     id: 1,
//     from: '0xadc3...msa',
//     to: '0xbcd4...nqw',
//     amount: 2456.88,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x1234...5678',
//     blockTime: '2024-11-25 02:44 AM',
//   },
//   {
//     id: 2,
//     from: '0xdef5...prs',
//     to: '0xefg6...tuv',
//     amount: 1823.45,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x8765...4321',
//     blockTime: '2024-11-25 01:30 AM',
//   },
//   {
//     id: 3,
//     from: '0xhij7...wxy',
//     to: '0xklm8...zab',
//     amount: 3567.92,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x9876...5432',
//     blockTime: '2024-11-24 11:15 PM',
//   },
//   {
//     id: 4,
//     from: '0xnop9...cde',
//     to: '0xqrs0...fgh',
//     amount: 945.67,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x2468...1357',
//     blockTime: '2024-11-24 10:20 PM',
//   },
//   {
//     id: 5,
//     from: '0xtuv1...ijk',
//     to: '0xwxy2...lmn',
//     amount: 4789.23,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x1357...2468',
//     blockTime: '2024-11-24 09:45 PM',
//   },
//   {
//     id: 6,
//     from: '0xzab3...opq',
//     to: '0xcde4...rst',
//     amount: 2134.56,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x3579...2468',
//     blockTime: '2024-11-24 08:30 PM',
//   },
//   {
//     id: 7,
//     from: '0xfgh5...uvw',
//     to: '0xijk6...xyz',
//     amount: 3678.91,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x4680...1357',
//     blockTime: '2024-11-24 07:15 PM',
//   },
//   {
//     id: 8,
//     from: '0xlmn7...abc',
//     to: '0xopq8...def',
//     amount: 1567.89,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x5791...2468',
//     blockTime: '2024-11-24 06:00 PM',
//   },
//   {
//     id: 9,
//     from: '0xrst9...ghi',
//     to: '0xuvw0...jkl',
//     amount: 5432.1,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x6802...1357',
//     blockTime: '2024-11-24 04:45 PM',
//   },
//   {
//     id: 10,
//     from: '0xxyz1...mno',
//     to: '0xabc2...pqr',
//     amount: 876.54,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x7913...2468',
//     blockTime: '2024-11-24 03:30 PM',
//   },
//   {
//     id: 11,
//     from: '0xdef3...stu',
//     to: '0xghi4...vwx',
//     amount: 4321.98,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x8024...1357',
//     blockTime: '2024-11-24 02:15 PM',
//   },
//   {
//     id: 12,
//     from: '0xjkl5...yza',
//     to: '0xmno6...bcd',
//     amount: 1234.56,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x9135...2468',
//     blockTime: '2024-11-24 01:00 PM',
//   },
//   {
//     id: 13,
//     from: '0xpqr7...efg',
//     to: '0xstu8...hij',
//     amount: 3456.78,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x0246...1357',
//     blockTime: '2024-11-24 11:45 AM',
//   },
//   {
//     id: 14,
//     from: '0xvwx9...klm',
//     to: '0xyza0...nop',
//     amount: 2345.67,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x1357...2468',
//     blockTime: '2024-11-24 10:30 AM',
//   },
//   {
//     id: 15,
//     from: '0xbcd1...qrs',
//     to: '0xefg2...tuv',
//     amount: 6789.12,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x2468...1357',
//     blockTime: '2024-11-24 09:15 AM',
//   },
//   {
//     id: 16,
//     from: '0xhij3...wxy',
//     to: '0xklm4...zab',
//     amount: 1987.65,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x3579...2468',
//     blockTime: '2024-11-24 08:00 AM',
//   },
//   {
//     id: 17,
//     from: '0xnop5...cde',
//     to: '0xqrs6...fgh',
//     amount: 4567.89,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x4680...1357',
//     blockTime: '2024-11-24 06:45 AM',
//   },
//   {
//     id: 18,
//     from: '0xtuv7...ijk',
//     to: '0xwxy8...lmn',
//     amount: 3219.87,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x5791...2468',
//     blockTime: '2024-11-24 05:30 AM',
//   },
//   {
//     id: 19,
//     from: '0xzab9...opq',
//     to: '0xcde0...rst',
//     amount: 5432.1,
//     type: 'send' as const,
//     currency: 'Drink',
//     txHash: '0x6802...1357',
//     blockTime: '2024-11-24 04:15 AM',
//   },
//   {
//     id: 20,
//     from: '0xfgh1...uvw',
//     to: '0xijk2...xyz',
//     amount: 1654.32,
//     type: 'receive' as const,
//     currency: 'Drink',
//     txHash: '0x7913...2468',
//     blockTime: '2024-11-24 03:00 AM',
//   },
// ];

// import { Button, DatePicker, Select, Space, Table, message } from 'antd';
// import axios from 'axios';
// import moment from 'moment';
// import React, { useEffect, useState } from 'react';

// const { RangePicker } = DatePicker;
// const { Option } = Select;

// function AllTransactions() {
//   const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
//   const [responseData, setResponseData] = useState<any[]>([]);
//   const [error, setError] = useState<string | null>(null);
//   const [loading, setLoading] = useState(false);
//   const [inflowOutflowTitle, setInflowOutflowTitle] = useState<string | undefined>(undefined);
//   const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
//   const [inflowOutflowTitles, setInflowOutflowTitles] = useState<string[]>([]);

//   const handleDateChange = (dates: any) => {
//     setDateRange(dates);
//   };

//   const handleTitleChange = (value: string) => {
//     setInflowOutflowTitle(value === 'All' ? undefined : value);
//   };

//   const fetchData = async (page = 1, pageSize = 10) => {
//     setLoading(true);
//     const accessToken = localStorage.getItem('access_token');

//     if (!accessToken) {
//       message.error('Access token not found. Please log in again.');
//       setLoading(false);
//       return;
//     }
//     //update this URL to one that's Live
//     let url = `http://localhost:8000/api/user/user-transactions/?page=${page}&page_size=${pageSize}`;

//     if (dateRange[0] && dateRange[1]) {
//       const startDate = dateRange[0].format('YYYY-MM-DD');
//       const endDate = dateRange[1].format('YYYY-MM-DD');
//       url += `&start_date=${startDate}&end_date=${endDate}`;
//     }

//     if (inflowOutflowTitle) {
//       url += `&inflow_outflow_title=${inflowOutflowTitle}`;
//     }

//     try {
//       const response = await axios.get(url, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });

//       setResponseData(response.data.results);
//       setPagination({
//         ...pagination,
//         current: page,
//         total: response.data.count,
//       });
//       setInflowOutflowTitles(['All', ...response.data.inflow_outflow_titles]);
//       setError(null);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setError((error as any).response?.data?.detail || 'An error occurred while fetching data');
//       setResponseData([]);

//       if ((error as any).response?.status === 401) {
//         message.error('Your session has expired. Please log in again.');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []); // Fetch data on component mount

//   const handleTableChange = (pagination: any) => {
//     fetchData(pagination.current, pagination.pageSize);
//   };

//   const columns = [
//     {
//       title: 'Transaction Hash',
//       dataIndex: 'transaction_hash',
//       key: 'transaction_hash',
//     },
//     {
//       title: 'Amount',
//       dataIndex: 'amount',
//       key: 'amount',
//       render: (value: any) => {
//         const numValue = parseFloat(value);
//         return isNaN(numValue) ? value : numValue.toFixed(2);
//       },
//     },
//     {
//       title: 'Receiver',
//       dataIndex: 'receiver',
//       key: 'receiver',
//     },
//     {
//       title: 'Sender',
//       dataIndex: 'sender',
//       key: 'sender',
//     },
//     {
//       title: 'Transfer Type',
//       dataIndex: 'transfer_type',
//       key: 'transfer_type',
//     },
//     {
//       title: 'Inflow/Outflow Title',
//       dataIndex: 'inflow_outflow_title',
//       key: 'inflow_outflow_title',
//     },
//     {
//       title: 'Block Time',
//       dataIndex: 'block_time',
//       key: 'block_time',
//       render: (value: string) => (moment(value).isValid() ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value),
//     },
//   ];

//   return (
//     <Space direction="vertical" size={12} style={{ width: '100%' }}>
//       <Space>
//         <RangePicker onChange={handleDateChange} />
//         <Select
//           style={{ width: 200 }}
//           placeholder="Select Inflow/Outflow Title"
//           onChange={handleTitleChange}
//           allowClear
//           defaultValue="All"
//         >
//           {inflowOutflowTitles.map((title) => (
//             <Option key={title} value={title}>
//               {title}
//             </Option>
//           ))}
//         </Select>
//         <Button onClick={() => fetchData(1, pagination.pageSize)}>Fetch Data</Button>
//       </Space>
//       {error && <div style={{ color: 'red' }}>{error}</div>}
//       <Table
//         columns={columns}
//         dataSource={responseData}
//         rowKey="transaction_hash"
//         pagination={pagination}
//         loading={loading}
//         onChange={handleTableChange}
//       />
//     </Space>
//   );
// }

// export default AllTransactions;

import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import axios from '../api/axios';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import AmountTab from '../components/common/AmountTab';
import FetchTx from '../components/common/FetchTx';
import SendReceiveBlock from '../components/common/SendReceiveBlock';
import Text from '../components/common/Text';
import { BalanceIcon } from '../Icons/BalanceIcon';
import TablePagination from '../pages/TablePagination';
import { useLoginStore } from '../store';
import { Transaction, TransactionResponse } from '../types/Types';
import { getExplorerUrl, openInNewTab } from '../utils/walletUtils/walletutils';

const AllTransactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;

  const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);

  useEffect(() => {
    console.log('chainId updated:', chainId);
  }, [chainId]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const response = await axios.get<TransactionResponse>(`/api/user/user-transactions/?page=${currentPage}`);
        setTransactionData(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const amountData = useMemo(
    () => [
      {
        title: 'Total Sent',
        amount: transactionData?.stats?.total_sent
          ? Number(transactionData.stats.total_sent).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : '0',
        percentage: transactionData?.stats?.sent_24h_change || '0',
        isPositive: Number(transactionData?.stats?.sent_24h_change || '0') >= 0,
        showReceiveBlock: false,
      },
      {
        title: 'Total Received',
        amount: transactionData?.stats?.total_received
          ? Number(transactionData.stats.total_received).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : '0',
        percentage: transactionData?.stats?.received_24h_change || '0',
        isPositive: Number(transactionData?.stats?.received_24h_change || '0') >= 0,
        showReceiveBlock: false,
      },
      {
        title: 'Largest Transaction',
        amount: transactionData?.stats?.largest_transaction?.amount
          ? Number(transactionData.stats.largest_transaction.amount).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          : '0',
        percentage: transactionData?.stats?.largest_transaction?.change_24h || '0',
        isPositive: Number(transactionData?.stats?.largest_transaction?.change_24h || '0') >= 0,
        showReceiveBlock: true,
      },
    ],
    [transactionData],
  );

  const filteredData = useMemo(() => {
    if (!transactionData?.results) return { data: [], totalCount: 0 };

    let filtered = transactionData.results;
    if (searchTerm) {
      filtered = filtered.filter(
        (tx) =>
          tx.sender.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.receiver.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.transaction_hash.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return {
      data: filtered,
      totalCount: transactionData.count,
    };
  }, [transactionData, searchTerm]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  const handleFetchWithDates = (dateRange: [Date | null, Date | null]) => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        let url = `/api/user/user-transactions/?page=${currentPage}`;

        if (dateRange[0] && dateRange[1]) {
          const startDate = dateRange[0].toISOString().split('T')[0];
          const endDate = dateRange[1].toISOString().split('T')[0];
          url += `&start_date=${startDate}&end_date=${endDate}`;
        }

        const response = await axios.get<TransactionResponse>(url);
        setTransactionData(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  };

  return (
    <Box
      display="flex"
      padding={['24px 12px', '24px 12px', '24px 12px', '14px 24px 40px 24px']}
      flexDirection="column"
      alignItems="center"
      gap={['24px', '24px', '24px', '36px']}
      flex="1 0 0"
      background={['var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'transparent']}
      alignSelf="stretch"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
      >
        <Text
          c="var(--Text-Primary-default, #000)"
          fs={['24px', '24px', '24px', '36px']}
          lineHeight="28px"
          textAlign={'left'}
        >
          Transaction History
        </Text>
        <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={400} lineHeight="22px">
          Track all your wallet&apos;s activity in one place.
        </Text>
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        gap="14px"
        alignSelf="stretch"
        flexDirection={['column', 'column', 'column', 'row']}
      >
        {amountData.map((item, index) => (
          <AmountTab
            key={index}
            title={item.title}
            amount={item.amount}
            percentage={item.percentage}
            isPositive={item.isPositive}
            showReceiveBlock={item.showReceiveBlock}
          />
        ))}
      </Box>

      <Box
        display="flex"
        padding={['24px 0px', '24px 0px', '24px 0px', '24px']}
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius={['0px', '0px', '0px', '36px']}
        border="1px solid rgba(255, 255, 255, 0.10)"
        background={['transparent', 'transparent', 'transparent', 'var(--Neutrals-1, #FFF)']}
        borderTop={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        borderBottom={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
      >
        {isMobile ? (
          <Flex flexDirection={'column'} width={'100%'} gap={'12px'}>
            <Flex
              justifyContent="space-between"
              width="100%"
              flex="1 0 0"
              flexDirection={['column', 'column', 'row']}
              alignItems={['flex-start', 'flex-start', 'center']}
              gap={'8px'}
            >
              <Text fs="20px" lineHeight="28px" textAlign={'left'}>
                Transactions
              </Text>
              <Flex justifyContent={'flex-end'}>
                <FetchTx onFetch={handleFetchWithDates} />
              </Flex>
            </Flex>

            <Flex gap="12px" justify="space-between" align="center" width={'100%'}>
              <InputGroup
                flex={1}
                width="fit-content"
                padding="0px 20px 0px 12px"
                gap="10px"
                border="0.5px solid var(--Neutrals-4, #E9E9E9)"
                borderRadius="16px"
                background="var(--Neutrals-2, #F6F6F6)"
                alignItems="center"
              >
                <Image src={SearchIcon} alt="search" cursor="pointer" />
                <Input
                  border="none"
                  color="#000"
                  padding={0}
                  placeholder="Search by Address or Tx Hash"
                  background="transparent"
                  value={searchTerm}
                  onChange={handleSearch}
                  _placeholder={{
                    color: 'var(--Text-Primary-description, #909090)',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                  _focus={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </InputGroup>
            </Flex>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" width="100%" flex="1 0 0" alignItems="center">
            <Text fs="20px" lineHeight="28px">
              Transactions
            </Text>

            <FetchTx onFetch={handleFetchWithDates} />

            <Flex gap="12px" justify="space-between" align="center">
              <InputGroup
                width="fit-content"
                padding="0px 20px 0px 12px"
                gap="10px"
                border="0.5px solid var(--Neutrals-4, #E9E9E9)"
                borderRadius="16px"
                background="var(--Neutrals-2, #F6F6F6)"
                alignItems="center"
              >
                <Image src={SearchIcon} alt="search" cursor="pointer" />
                <Input
                  border="none"
                  color="#000"
                  padding={0}
                  placeholder="Search by Address or Tx Hash"
                  background="transparent"
                  value={searchTerm}
                  onChange={handleSearch}
                  _placeholder={{
                    color: 'var(--Text-Primary-description, #909090)',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                  _focus={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </InputGroup>
            </Flex>
          </Flex>
        )}

        <Table
          variant="unstyled"
          alignItems={'flex-start'}
          display={'flex'}
          flexDirection={'column'}
          overflowX={'auto'}
        >
          <Thead width={'100%'}>
            <Tr
              display="flex"
              width={['1228px', '1228px', '1228px', '1228px', '100%']}
              height="40px"
              padding="0px 12px"
              alignItems="center"
              borderRadius="12px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Amount
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Sender
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Receiver
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Tx Hash
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Tx Type
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                textAlign="right"
                lineHeight="22px"
                justifyContent="flex-end"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Block Time
              </Th>
            </Tr>
          </Thead>

          <Tbody width={'100%'}>
            {filteredData.data.map((transaction: Transaction) => (
              <Tr
                key={transaction.id}
                display="flex"
                width={['1228px', '1228px', '1228px', '1228px', '100%']}
                alignItems="center"
                padding="12px"
                _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                borderBottom="1px solid rgba(246, 246, 246, 0.8)"
              >
                <Td padding="0px" flex="1" border="none" display="flex" gap="8px" paddingRight="12px">
                  <BalanceIcon fill={transaction.transfer_type === 'Receive' ? '#39CA7F' : '#FF4D4F'} />
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign="left">
                    {Number(transaction.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight="12px" flex="1" border="none">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign="left">
                    {`${transaction.sender.slice(0, 6)}...${transaction.sender.slice(-3)}`}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight="12px" flex="1" border="none">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign="left">
                    {`${transaction.receiver.slice(0, 6)}...${transaction.receiver.slice(-3)}`}
                  </Text>
                </Td>
                <Td
                  padding="0px"
                  paddingRight="12px"
                  flex="1"
                  border="none"
                  onClick={() => openInNewTab(getExplorerUrl(Number(chainId), transaction.transaction_hash))}
                  cursor={'pointer'}
                >
                  <Text
                    c="var(--Text-action, #4096FF)"
                    fs="14px"
                    fw={500}
                    lineHeight="22px"
                    padding={0}
                    textAlign="left"
                  >
                    {`${transaction.transaction_hash.slice(0, 6)}...${transaction.transaction_hash.slice(-4)}`}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight="12px" flex="1" border="none">
                  <Flex
                    alignItems="center"
                    gap="4px"
                    borderRadius="4px"
                    cursor="pointer"
                    onClick={() => openInNewTab(getExplorerUrl(Number(chainId), transaction.transaction_hash))}
                  >
                    <SendReceiveBlock type={transaction.transfer_type.toLowerCase() as 'send' | 'receive'} />
                  </Flex>
                </Td>
                <Td flex="1" border="none" padding="0px" paddingRight="12px" display="flex" justifyContent="flex-end">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0}>
                    {new Date(transaction.block_time).toLocaleString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <TablePagination
          currentPage={currentPage}
          totalCount={transactionData?.count || 0}
          entriesPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
          hasNext={!!transactionData?.next}
          hasPrevious={!!transactionData?.previous}
        />
      </Box>
    </Box>
  );
};

export default AllTransactions;

import { Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ArrowD from '../../assets/images/svgs/ArrowD.svg';
import ArrowUp from '../../assets/images/svgs/ArrowUp.svg';
import Info from '../../assets/images/svgs/Info.svg';
import { ChartData, VolumeData } from '../../types/Types';
import Text from '../common/Text';

// Local interface for chart data points
interface ChartDataPoint extends ChartData {
  day: string;
  displayValue: number;
  fill: string;
  formattedDate: string;
}

const formatDate = (timestamp: string): string => {
  try {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { weekday: 'short' }).slice(0, 3);
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const formatFullDate = (timestamp: string): string => {
  try {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const formatVolume = (value: number): string => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  } catch (error) {
    console.error('Error formatting volume:', error);
    return '$0';
  }
};

const VolumeChart: React.FC<{ volumeData?: VolumeData }> = ({ volumeData }) => {
  const [selectedDay, setSelectedDay] = useState<ChartDataPoint | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  // Process the volume data for the chart
  const processedData: ChartDataPoint[] = React.useMemo(() => {
    if (!volumeData?.charts?.volume?.data) return [];

    return volumeData.charts.volume.data.map((item) => ({
      ...item,
      day: formatDate(item.timestamp),
      displayValue: item.value,
      fill: '#F7F7F7',
      formattedDate: formatFullDate(item.timestamp),
    }));
  }, [volumeData]);

  // Calculate the maximum value for scaling
  const maxValue = React.useMemo(() => {
    if (processedData.length === 0) return 0;
    return Math.max(...processedData.map((item) => item.value));
  }, [processedData]);

  // Convert values to percentages for display
  const chartData: ChartDataPoint[] = React.useMemo(() => {
    if (maxValue === 0) return [];

    return processedData.map((item) => ({
      ...item,
      value: (item.value / maxValue) * 100,
      fill: item.timestamp === selectedDay?.timestamp ? '#4096FF' : '#F7F7F7',
    }));
  }, [processedData, maxValue, selectedDay]);

  useEffect(() => {
    if (chartData.length > 0 && !selectedDay) {
      setSelectedDay(chartData[chartData.length - 1]);
    }
  }, [chartData]);

  const handleDaySelect = (dayData: ChartDataPoint) => {
    setSelectedDay(dayData);
  };

  const volumeChange = selectedDay?.percent_change_7d || 0;
  const currentVolume = selectedDay?.displayValue || 0;
  const isPositiveChange = volumeChange >= 0;

  if (!volumeData) {
    return (
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        gap="8px"
        flex="1 0 0"
        height="100%"
        borderRadius={['24px', '24px', '24px', '36px']}
        border={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(255, 255, 255, 0.10)',
        ]}
        background="var(--Neutrals-1, #FFF)"
        boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
        justifyContent="center"
        alignItems="center"
      >
        <Text fs="14px" fw={500} c="var(--Text-Primary-description, #909090)">
          Loading volume data...
        </Text>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      padding="24px"
      flexDirection="column"
      gap="8px"
      flex="1 0 0"
      height="100%"
      borderRadius={['24px', '24px', '24px', '36px']}
      border={[
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(0, 0, 0, 0.05)',
        '1px solid rgba(255, 255, 255, 0.10)',
      ]}
      background="var(--Neutrals-1, #FFF)"
      boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gap={['5px', '5px', '5px', '18px']}
        alignSelf="stretch"
        mb="16px"
      >
        <Flex gap={'18px'} flexDirection={'column'}>
          <Flex alignItems="center" gap="8px" alignSelf="stretch">
            <Tooltip
              label="Track how trading volume changes over the past 7 days."
              bg="white"
              padding={'12px'}
              color="black"
              hasArrow
              placement="top"
              borderRadius="8px"
              boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            >
              <Box>
                <Image src={Info} alt="info" width={'16px'} height={'16px'} />
              </Box>
            </Tooltip>
            <Text fontSize="sm" fontWeight="bold" color="gray.500">
              Daily Volume
            </Text>
          </Flex>

          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="8px">
            <Text fs={['32px', '32px', '32px', '42px', '42px', '42px']} lineHeight="52px" letterSpacing="-1.2px">
              {formatVolume(currentVolume)}
            </Text>
          </Box>
        </Flex>

        <Box display="flex" alignItems="center" gap="8px">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Image src={isPositiveChange ? ArrowUp : ArrowD} alt="trend arrow" />
            <Text fs="14px" fw={500} c={isPositiveChange ? 'var(--green-5, #39CA7F)' : 'var(--red-5, #FF4D4F)'}>
              {Math.abs(volumeChange).toFixed(2)}%
            </Text>
          </Box>
          <Text c="var(--Neutrals-6, #909090)" fs="14px" fw={500}>
            vs last 7 days
          </Text>
        </Box>
      </Flex>

      <Flex direction="column" gap="16px">
        <Flex gap="8px" height="120px">
          {chartData.map((item, index) => (
            <Tooltip
              key={`bar-${index}`}
              label={
                <Box p="2">
                  <Text fontSize="12px" color="#666" mb="1">
                    {item.formattedDate}
                  </Text>
                  <Text fontSize="14px" fontWeight="bold">
                    {formatVolume(item.displayValue)}
                  </Text>
                </Box>
              }
              bg="white"
              color="black"
              hasArrow
              placement="top"
              borderRadius="8px"
              boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            >
              <Box
                flex="1"
                position="relative"
                cursor="pointer"
                onClick={() => handleDaySelect(item)}
                role="button"
                aria-label={`Volume for ${item.day}`}
              >
                <Box
                  position="absolute"
                  bottom="0"
                  width="100%"
                  height={`${item.value}%`}
                  backgroundColor={item.timestamp === selectedDay?.timestamp ? '#4096FF' : '#F7F7F7'}
                  borderRadius="12px"
                  transition="background-color 0.2s"
                />
              </Box>
            </Tooltip>
          ))}
        </Flex>

        <Flex width="100%" gap="8px">
          {chartData.map((item, index) => (
            <Box
              key={`day-${index}`}
              display="flex"
              height="32px"
              padding="8px 4px"
              justifyContent="center"
              alignItems="center"
              flex="1"
              cursor="pointer"
              borderRadius="100px"
              background={item.timestamp === selectedDay?.timestamp ? 'var(--blue-1, #E6F4FF)' : 'transparent'}
              _hover={{
                background:
                  item.timestamp === selectedDay?.timestamp ? 'var(--blue-1, #E6F4FF)' : 'rgba(230, 244, 255, 0.5)',
              }}
              transition="background 0.2s"
              onClick={() => handleDaySelect(item)}
              role="button"
              aria-label={`Select ${item.day}`}
              aria-pressed={item.timestamp === selectedDay?.timestamp}
            >
              <Text
                fontSize="14px"
                fontWeight="500"
                lineHeight="16px"
                color={
                  item.timestamp === selectedDay?.timestamp
                    ? 'var(--Text-action, #4096FF)'
                    : 'var(--Neutrals-6, #909090)'
                }
                fontFamily="Poppins"
              >
                {item.day}
              </Text>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default VolumeChart;

// import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
// import React, { KeyboardEvent, useEffect, useState } from 'react';
// import axios from '../../api/axios';
// import AppModal from './AppModal';

// interface TagEditModalProps {
//   open: boolean;
//   onClose: () => void;
//   id: number;
//   initialTags: string[];
//   onTagsUpdated: (updatedTags: string[]) => void;
// }

// const MAX_TAGS = 3;

// const TagEditModal = ({ open, onClose, id, initialTags = [], onTagsUpdated }: TagEditModalProps) => {
//   const [tags, setTags] = useState<string[]>(initialTags);
//   const [newTagInput, setNewTagInput] = useState('');

//   useEffect(() => {
//     if (open) {
//       // Create a new array with unique tags
//       const uniqueTags = Array.from(new Set(initialTags));
//       setTags(uniqueTags);
//       setNewTagInput('');
//     }
//   }, [open, initialTags]);

//   const handleRemoveTag = (tagToRemove: string) => {
//     setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
//   };

//   const handleAddNewTag = (newTag: string) => {
//     const trimmedTag = newTag.trim();
//     if (trimmedTag && !tags.includes(trimmedTag) && tags.length < MAX_TAGS) {
//       setTags((prevTags) => [...prevTags, trimmedTag]);
//       setNewTagInput('');
//     }
//   };

//   const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === 'Enter' && newTagInput.trim()) {
//       e.preventDefault();
//       handleAddNewTag(newTagInput);
//     }
//   };

//   const handleSave = async () => {
//     try {
//       const response = await axios.put(`/api/token-holders/${id}/update/`, {
//         labels: tags,
//       });
//       if (response.status === 200) {
//         onTagsUpdated(response.data.labels);
//         onClose();
//       }
//     } catch (error) {
//       console.error('Error updating tags:', error);
//       // Handle error
//     }
//   };

//   return (
//     <AppModal open={open} onClose={onClose}>
//       <Box width="100%" display={'flex'} flexDirection={'column'} gap={'24px'}>
//         <Flex justifyContent="space-between" display={'flex'} flexDirection={'column'} alignItems="center" gap={'12px'}>
//           <Text
//             width={'100%'}
//             color="var(--Text-Black, #000)"
//             fontFamily="Poppins"
//             fontSize="24px"
//             fontWeight="600"
//             textAlign={'center'}
//             margin={0}
//           >
//             Add a name tag
//           </Text>

//           <Text
//             color="var(--Text-Primary-description, #909090)"
//             fontFamily="Poppins"
//             fontSize="16px"
//             fontWeight="400"
//             textAlign={'center'}
//             margin={0}
//           >
//             Add a unique tag to help distinguish this address for tracking and insights. (Max 3 tags)
//           </Text>
//         </Flex>

//         <Box
//           padding="6px 12px 6px 6px"
//           alignItems="center"
//           gap="8px"
//           borderRadius="16px"
//           background="var(--Neutrals-2, #F6F6F6);"
//           border="0.5px solid var(--Neutrals-4, #E9E9E9)"
//           minHeight="120px"
//         >
//           <Flex gap="8px" flexWrap="wrap" alignItems="center">
//             {tags.map((tag) => (
//               <Flex
//                 key={tag}
//                 alignItems="center"
//                 borderRadius="10px"
//                 background="#FFF"
//                 boxShadow={'0px 1px 2px 0px rgba(0, 0, 0, 0.15)'}
//                 padding={'7px 4px'}
//               >
//                 <Flex alignItems="baseline" justify={'center'} gap="4px">
//                   <Text
//                     as="span"
//                     color="#909090"
//                     fontSize="16px"
//                     fontWeight="bold"
//                     cursor="pointer"
//                     onClick={() => handleRemoveTag(tag)}
//                   >
//                     ×
//                   </Text>
//                   <Text color="var(--Text-Primary-default, #000)" fontFamily="Poppins" fontSize="14px" fontWeight="500">
//                     {tag}
//                   </Text>
//                 </Flex>
//               </Flex>
//             ))}
//             {tags.length < MAX_TAGS && (
//               <Input
//                 placeholder="Type & Press Enter to Add tag"
//                 value={newTagInput}
//                 onChange={(e) => setNewTagInput(e.target.value)}
//                 onKeyPress={handleKeyPress}
//                 borderRadius="10px"
//                 background="#FFF"
//                 boxShadow={'0px 1px 2px 0px rgba(0, 0, 0, 0.15)'}
//                 padding={'7px 4px'}
//                 width="196px"
//                 _placeholder={{
//                   color: 'var(--Text-Primary-description, #909090)',
//                   fontSize: '14px',
//                 }}
//                 _focus={{
//                   border: 'none',
//                   boxShadow: 'none',
//                   background: 'white',
//                 }}
//               />
//             )}
//           </Flex>
//         </Box>

//         <Button
//           display="flex"
//           padding="6px var(--Button-paddingContentHorizontal, 16px)"
//           flexDirection="column"
//           justifyContent="center"
//           alignItems="center"
//           gap="8px"
//           alignSelf="stretch"
//           borderRadius="16px"
//           border="1px solid #2D8DFB"
//           background="#2D8DFB"
//           onClick={handleSave}
//         >
//           Add tag
//         </Button>
//       </Box>
//     </AppModal>
//   );
// };

// export default TagEditModal;

// import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
// import React, { useEffect, useState } from 'react';
// import axios from '../../api/axios';
// import AppModal from './AppModal';

// interface TagEditModalProps {
//   open: boolean;
//   onClose: () => void;
//   id: number;
//   initialTags: string[];
//   onTagsUpdated: (updatedTags: string[]) => void;
// }

// const MAX_TAGS = 3;

// const TagEditModal = ({ open, onClose, id, initialTags = [], onTagsUpdated }: TagEditModalProps) => {
//   const [tags, setTags] = useState<string[]>(initialTags);
//   const [newTagInput, setNewTagInput] = useState('');

//   useEffect(() => {
//     if (open) {
//       const uniqueTags = Array.from(new Set(initialTags));
//       setTags(uniqueTags);
//       setNewTagInput('');
//     }
//   }, [open, initialTags]);

//   const handleRemoveTag = (tagToRemove: string) => {
//     setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
//   };

//   const handleAddNewTag = (newTag: string) => {
//     const trimmedTag = newTag.trim();
//     if (trimmedTag && !tags.includes(trimmedTag) && tags.length < MAX_TAGS) {
//       setTags((prevTags) => [...prevTags, trimmedTag]);
//       setNewTagInput('');
//       handleSave([...tags, trimmedTag]); // Save immediately after adding
//     }
//   };

//   const handleSave = async (tagsToSave = tags) => {
//     try {
//       const response = await axios.put(`/api/token-holders/${id}/update/`, {
//         labels: tagsToSave,
//       });
//       if (response.status === 200) {
//         onTagsUpdated(response.data.labels);
//         onClose();
//       }
//     } catch (error) {
//       console.error('Error updating tags:', error);
//     }
//   };

//   return (
//     <AppModal open={open} onClose={onClose}>
//       <Box width="100%" display={'flex'} flexDirection={'column'} gap={'24px'}>
//         <Flex justifyContent="space-between" display={'flex'} flexDirection={'column'} alignItems="center" gap={'12px'}>
//           <Text
//             width={'100%'}
//             color="var(--Text-Black, #000)"
//             fontFamily="Poppins"
//             fontSize="24px"
//             fontWeight="600"
//             textAlign={'center'}
//             margin={0}
//           >
//             Add a name tag
//           </Text>

//           <Text
//             color="var(--Text-Primary-description, #909090)"
//             fontFamily="Poppins"
//             fontSize="16px"
//             fontWeight="400"
//             textAlign={'center'}
//             margin={0}
//           >
//             Add a unique tag to help distinguish this address for tracking and insights.
//           </Text>
//         </Flex>

//         <Box padding="0" alignItems="center" gap="16px" display="flex" flexDirection="column">
//           <Input
//             value={newTagInput}
//             onChange={(e) => setNewTagInput(e.target.value)}
//             background="#F8F8F8"
//             border="none"
//             borderRadius="16px"
//             height="48px"
//             width="100%"
//             placeholder="Enter tag name"
//             _placeholder={{ color: '#909090' }}
//             padding="0 16px"
//             fontSize="16px"
//           />

//           <Button
//             width="100%"
//             height="48px"
//             background="#2D8DFB"
//             color="white"
//             borderRadius="16px"
//             onClick={() => {
//               if (newTagInput.trim()) {
//                 handleAddNewTag(newTagInput);
//               }
//             }}
//             _hover={{ background: '#2171d1' }}
//             isDisabled={!newTagInput.trim() || tags.length >= MAX_TAGS}
//           >
//             Add tag
//           </Button>
//         </Box>
//       </Box>
//     </AppModal>
//   );
// };

// export default TagEditModal;

// TagEditModal.tsx
import { Box, Button, Flex, Image, Input, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CrossIcon from '../../assets/images/svgs/CrossIcon.svg';
import AppModal from './AppModal';

interface TagEditModalProps {
  open: boolean;
  onClose: () => void;
  id: number;
  initialTags: string[];
  onTagsUpdated: (updatedTag: string) => void;
}

const TagEditModal = ({ open, onClose, id, initialTags = [], onTagsUpdated }: TagEditModalProps) => {
  const [currentTag, setCurrentTag] = useState('');

  useEffect(() => {
    if (open) {
      // Set the initial tag if it exists
      setCurrentTag(initialTags[0] || '');
    }
  }, [open, initialTags]);

  const handleSave = () => {
    if (currentTag.trim()) {
      onTagsUpdated(currentTag.trim());
      onClose(); // Close the modal after saving the tag
    }
  };

  const handleClose = () => {
    onClose(); // Close the modal without saving the tag
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  return (
    <>
      {isMobile ? (
        <AppModal open={open} onClose={handleClose} width={'100%'}>
          <Box width="100%" display={'flex'} flexDirection={'column'} gap={'24px'} position={'relative'}>
            <Flex
              justifyContent="space-between"
              display={'flex'}
              flexDirection={'column'}
              alignItems="center"
              gap={'12px'}
            >
              <Text
                width={'100%'}
                color="var(--Text-Black, #000)"
                fontFamily="Poppins"
                fontSize="18px"
                fontWeight="600"
                textAlign={'center'}
                margin={0}
              >
                Add or Update Tag
              </Text>
              <Image
                onClick={handleClose}
                cursor="pointer"
                src={CrossIcon}
                alt="Cross"
                width={'24px'}
                height={'24px'}
                right={0}
                top={0}
                position={'absolute'}
              />
              <Text
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="16px"
                fontWeight="400"
                textAlign={'center'}
                margin={0}
              >
                Add a tag to help identify this address for tracking and insights.
              </Text>
            </Flex>

            <Box padding="0" alignItems="center" gap="16px" display="flex" flexDirection="column">
              <Input
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                background="#F8F8F8"
                border="none"
                borderRadius="16px"
                height="48px"
                width="100%"
                fontFamily="Poppins"
                placeholder="Enter tag name"
                _placeholder={{
                  color: 'var(--Neutrals-300, #C2C2C2)',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
                padding="0 16px"
                fontSize="16px"
              />

              <Button
                width="100%"
                height="48px"
                background="#2D8DFB"
                color="white"
                borderRadius="16px"
                onClick={handleSave}
                _hover={{ background: '#2171d1' }}
                isDisabled={!currentTag.trim()}
              >
                Save Tag
              </Button>
            </Box>
          </Box>
        </AppModal>
      ) : (
        <AppModal open={open} onClose={handleClose}>
          <Box width="100%" display={'flex'} flexDirection={'column'} gap={'24px'} position={'relative'}>
            <Flex
              justifyContent="space-between"
              display={'flex'}
              flexDirection={'column'}
              alignItems="center"
              gap={'12px'}
            >
              <Text
                width={'100%'}
                color="var(--Text-Black, #000)"
                fontFamily="Poppins"
                fontSize="18px"
                fontWeight="600"
                textAlign={'center'}
                margin={0}
              >
                Add or Update Tag
              </Text>
              <Image
                onClick={handleClose}
                cursor="pointer"
                src={CrossIcon}
                alt="Cross"
                width={'24px'}
                height={'24px'}
                right={0}
                top={0}
                position={'absolute'}
              />
              <Text
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="16px"
                fontWeight="400"
                textAlign={'center'}
                margin={0}
              >
                Add a tag to help identify this address for tracking and insights.
              </Text>
            </Flex>

            <Box padding="0" alignItems="center" gap="16px" display="flex" flexDirection="column">
              <Input
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                background="#F8F8F8"
                border="none"
                borderRadius="16px"
                height="48px"
                width="100%"
                placeholder="Enter tag name"
                _placeholder={{
                  color: 'var(--Neutrals-300, #C2C2C2)',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
                padding="0 16px"
                fontSize="16px"
              />

              <Button
                width="100%"
                height="48px"
                background="#2D8DFB"
                color="white"
                borderRadius="16px"
                onClick={handleSave}
                _hover={{ background: '#2171d1' }}
                isDisabled={!currentTag.trim()}
              >
                Save Tag
              </Button>
            </Box>
          </Box>
        </AppModal>
      )}
    </>
  );
};

export default TagEditModal;

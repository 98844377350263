import { Modal, ModalProps } from 'antd';
import React from 'react';

// Create a custom width type that includes responsive array
type ResponsiveWidth = string | number | (string | number)[];

// Modify the Props type to use our custom width type
type Props = Omit<ModalProps, 'styles' | 'width'> & {
  styles?: {
    body?: React.CSSProperties;
    content?: React.CSSProperties;
    mask?: React.CSSProperties;
  };
  width?: ResponsiveWidth;
};

const AppModal: React.FC<Props> = ({ styles, width, ...props }) => {
  // Convert responsive array width to single value if needed
  const modalWidth = Array.isArray(width) ? width[0] : width;

  return (
    <Modal
      centered
      title={null}
      footer={null}
      closeIcon={null}
      width={modalWidth}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          ...styles?.body,
        },
        content: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '20px',
          background: 'var(--Cascader-colorWhite, #FFF)',
          boxShadow: '0px 1.737px 5.211px 0px rgba(0, 0, 0, 0.15)',
          padding: '24px',
          ...styles?.content,
        },
        mask: {
          width: '100vw',
          backdropFilter: 'blur(4px)',
          backgroundColor: '#000000BF',
          ...styles?.mask,
        },
      }}
      {...props}
    />
  );
};

export default AppModal;

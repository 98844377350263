import { Button, Flex, Image } from '@chakra-ui/react';
import { DatePicker, Popover } from 'antd';
import React, { useState } from 'react';
import arrowRight from '../../assets/images/svgs/arrowRight.svg';
import CalendarOutlined from '../../assets/images/svgs/CalendarOutlined.svg';
import Text from './Text';
const { RangePicker } = DatePicker;

interface DateRangeProps {
  onFetch: (dateRange: [Date | null, Date | null]) => void;
}

const FetchTx: React.FC<DateRangeProps> = ({ onFetch }) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [selectedDateText, setSelectedDateText] = useState({
    startDate: 'Start Date',
    endDate: 'End Date',
  });

  const handleDateChange = (dates: any) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start?.toDate() || null, end?.toDate() || null]);
      setSelectedDateText({
        startDate: start ? start.format('MM/DD/YYYY') : 'Start Date',
        endDate: end ? end.format('MM/DD/YYYY') : 'End Date',
      });
    } else {
      setDateRange([null, null]);
      setSelectedDateText({
        startDate: 'Start Date',
        endDate: 'End Date',
      });
    }
  };

  const handleFetch = () => {
    onFetch(dateRange);
  };

  const datePickerContent = <RangePicker onChange={handleDateChange} style={{ border: 'none' }} />;

  return (
    <Flex display="flex" width="fit-content" gap={'12px'} justifyContent="space-between" alignItems="center">
      <Popover content={datePickerContent} trigger="click" placement="bottom">
        <Flex
          display="flex"
          padding="10px 10px 10px 12px"
          alignItems="center"
          gap="10px"
          borderRadius="16px"
          background="var(--Neutrals-2, #F6F6F6)"
          cursor="pointer"
        >
          <Text c="var(--Neutrals-6, #909090)" fs="14px" fw={400} lineHeight="22px">
            {selectedDateText.startDate}
          </Text>
          <Image src={arrowRight} />
          <Text c="var(--Neutrals-6, #909090)" fs="14px" fw={400} lineHeight="22px">
            {selectedDateText.endDate}
          </Text>
          <Image src={CalendarOutlined} />
        </Flex>
      </Popover>
      <Button
        onClick={handleFetch}
        display="flex"
        padding="6px 16px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        borderRadius="16px"
        border="1px solid rgba(0, 0, 0, 0.15)"
        background="var(--Button-colorBgContainer, #FFF)"
        color="rgba(0, 0, 0, 0.88)"
        fontFamily="Poppins"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="22px"
      >
        Fetch
      </Button>
    </Flex>
  );
};

export default FetchTx;

import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import React, { forwardRef, useRef } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import Text from '../../common/Text';

interface PasswordFieldProps extends InputProps {
  password: string;
  setPassword: (password: string) => void;
  placeholder?: string; // Placeholder for the password field
  label?: string; // Label text for the field
  isRequired?: boolean; // Whether the `*` should appear next to the label
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const mergeRef = useMergeRefs(inputRef, ref);

  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setPassword(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <FormControl display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
      <Text c="var(--Neutrals-500, #8A8A8A)" fs="14px" lineHeight="16px" fw={400} textAlign="left">
        {props.label || 'Password'}{' '}
        {props.isRequired && (
          <span
            style={{
              color: 'var(--Red-500, #E35A5A)',

              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '16px',
            }}
          >
            *
          </span>
        )}
      </Text>
      <InputGroup border="none">
        <InputRightElement height="100%">
          <IconButton
            variant="text"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff color="#C2C2C2" /> : <HiEye color="#909090" />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          id="password"
          ref={mergeRef}
          name="password"
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          required={props.isRequired}
          onChange={handlePasswordChange}
          {...props}
          placeholder={props.placeholder || 'Password'}
          display="flex"
          height="48px"
          padding="8px 13px"
          alignItems="center"
          gap="10px"
          fontFamily={'Poppins'}
          alignSelf="stretch"
          _placeholder={{
            color: 'var(--Neutrals-300, #C2C2C2)',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}
          borderRadius="14px"
          border="0.5px solid var(--Neutrals-200, #DEDEDE)"
          background="var(--Neutrals-100, #FAFAFA)"
        />
      </InputGroup>
    </FormControl>
  );
});

PasswordField.displayName = 'PasswordField';

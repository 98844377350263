import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import axios from '../../api/axios';
import TablePagination from '../../pages/TablePagination';
import Text from './Text';

interface TokenData {
  id: number;
  token_name: string;
  token_balance: number;
  logo_url: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  weekly_values: number[];
  monthly_values: number[];
  daily_percent_change: number;
  weekly_percent_change: number;
  monthly_percent_change: number;
  balance_updated_at: string;
  token_holder_wallet: number;
}

interface TokenResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: TokenData[];
}

interface AssestCompProps {
  walletAddress?: string;
}

const ITEMS_PER_PAGE = 10;

const AssestComp: React.FC<AssestCompProps> = ({ walletAddress }) => {
  const [tokens, setTokens] = React.useState<TokenData[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [hasNext, setHasNext] = React.useState(false);
  const [hasPrevious, setHasPrevious] = React.useState(false);

  React.useEffect(() => {
    const fetchTokens = async () => {
      try {
        setLoading(true);
        const response = await axios.get<TokenResponse>(
          `/api/user/wallet-tokens/?address=${
            walletAddress || '0x0d0707963952f2fba59dd06f2b425ace40b492fe'
          }&page=${currentPage}&page_size=${ITEMS_PER_PAGE}`,
        );

        if (response.data) {
          setTokens(response.data.results);
          setTotalCount(response.data.count);
          setHasNext(!!response.data.next);
          setHasPrevious(!!response.data.previous);
          setError(null);
        }
      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to fetch token data');
        console.error('Error fetching tokens:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, [walletAddress, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <Box p={4}>
        <Text fs="14px" lineHeight="20px">
          Loading assets...
        </Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Text fs="14px" lineHeight="20px" c="red.500">
          {error}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      gap={4}
      height={['fit-content', 'fit-content', 'fit-content', '510px']}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={4}
        flex="1"
        overflow="auto"
        maxHeight="72%"
        sx={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
            background: 'var(--Neutrals-4, #E9E9E9)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--Neutrals-6, #909090)',
            borderRadius: '24px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'var(--Neutrals-8, #262626)',
          },
        }}
      >
        {tokens.map((token) => (
          <Flex
            key={token.id}
            display="flex"
            padding="0px 12px 16px 12px"
            alignItems="center"
            justifyContent="space-between"
            alignSelf="stretch"
            background="var(--Neutrals-1, #FFF)"
            borderBottom="1px solid rgba(246, 246, 246, 0.50)"
            width="100%"
          >
            <Flex align="center" gap="12px">
              <div
                style={{
                  background: 'black',
                  fontSize: '12px',
                  color: 'white',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {token.token_name.charAt(0).toUpperCase()}
              </div>
              <Box
                display="flex"
                padding="0px 10px"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                gap="8px"
                flex="1 0 0"
              >
                <Text fs="16px" lineHeight="20px" fw={500} textTransform="capitalize">
                  {token.token_name}
                </Text>
              </Box>
            </Flex>

            <Box
              display="flex"
              padding="0px 10px"
              justifyContent="flex-end"
              flexDirection="column"
              alignItems="flex-end"
              gap="8px"
              flex="1 0 0"
            >
              <Text fs="16px" lineHeight="20px" textTransform="capitalize" fw={500} textAlign="right">
                {token.token_balance.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>

      <Box width="100%" bg="white" pt={2} borderTop="1px solid rgba(246, 246, 246, 0.50)">
        <TablePagination
          currentPage={currentPage}
          totalCount={totalCount}
          entriesPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      </Box>
    </Box>
  );
};

export default AssestComp;

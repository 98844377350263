import { Box, Button, FormControl, Input, InputGroup } from '@chakra-ui/react';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '../../../../common/Text';

export const PassStep1: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const navigate = useNavigate();

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(validateEmail(newEmail));
  };

  const handleSubmit = (): void => {
    if (isValid) {
      navigate('/passstep-2', { state: { email } });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      paddingTop="100px"
      flexDirection="column"
      alignItems="center"
      width={'100%'}
      bg="var(--Neutrals-1, #FFF)"
    >
      <Box
        display="flex"
        padding={['0px 12px', '0px 24px', '0px']}
        width={['100%', '100%', 'fit-content', '410px']}
        flexDirection="column"
        gap="36px"
        borderRadius="16px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs={'28px'}>Reset your password</Text>
          <Text fs={'14px'} fw={500} lineHeight={'22px'} c="var(--Text-Primary-description, #909090)">
            A password reset link will be sent to your email address
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="24px" alignSelf="stretch">
          <FormControl display="flex" flexDirection="column" alignItems="flex-start" gap="20px" alignSelf="stretch">
            <InputGroup display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
              <Text
                c="var(--Neutrals-500, #8A8A8A)"
                fs="14px"
                ff="Inter"
                lineHeight={'16px'}
                fw={400}
                textAlign={'left'}
              >
                Email address
              </Text>
              <Input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleEmailChange}
                display="flex"
                height="48px"
                padding="8px 13px"
                alignItems="center"
                gap="10px"
                alignSelf="stretch"
                borderRadius="14px"
                border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                background="var(--Neutrals-100, #FAFAFA)"
              />
            </InputGroup>
          </FormControl>
          <Button
            onClick={handleSubmit}
            display="flex"
            alignItems="center"
            gap="var(--Checkbox-marginXS, 8px)"
            borderRadius="16px"
            border="1px solid #2D8DFB"
            background="#2D8DFB"
            _hover={{ background: '#2D8DFB' }}
            color="var(--Button-colorTextLightSolid, #FFF)"
            fontFamily="Poppins"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            width={'100%'}
            lineHeight="22px"
            isDisabled={!isValid}
            opacity={isValid ? 1 : 0.6}
            cursor={isValid ? 'pointer' : 'not-allowed'}
          >
            <span>Next</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PassStep1;

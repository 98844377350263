import { Text as ChakraText, TextProps as ChakraTextProps, ResponsiveValue } from '@chakra-ui/react';
import { Property } from 'csstype'; // Import Chakra's types for CSS properties
import React from 'react';

// Define the custom TextProps interface
interface TextProps extends ChakraTextProps {
  c?: string; // Shortcut for color
  ff?: string; // Shortcut for font-family
  fs?: ResponsiveValue<string>; // Allow responsive font-size
  fw?: ResponsiveValue<string | number>; // Allow responsive font-weight
  ta?: ResponsiveValue<Property.TextAlign>; // Use correct typing for text-align
  children?: React.ReactNode; // Content of the Text component
}

// Custom Text component
const Text: React.FC<TextProps> = ({ c, ff, fs, fw, ta, children, ...props }) => {
  // Default styles
  const defaultStyles = {
    color: 'var(--Text-Black, #000)', // Default color
    textAlign: 'center' as Property.TextAlign, // Default alignment
    fontFamily: 'Poppins', // Default font-family
    fontSize: '20px', // Default font-size
    fontWeight: '600', // Default font-weight
  };

  return (
    <ChakraText
      color={c || defaultStyles.color}
      textAlign={ta || defaultStyles.textAlign} // Pass correct textAlign type
      fontFamily={ff || defaultStyles.fontFamily}
      fontSize={fs || defaultStyles.fontSize}
      fontWeight={fw || defaultStyles.fontWeight}
      {...props} // Spread additional ChakraTextProps
    >
      {children}
    </ChakraText>
  );
};

export default Text;

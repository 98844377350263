import { Box } from '@chakra-ui/react';
import React from 'react';
import Text from './Text';

interface iProp {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const TimeTab: React.FC<iProp> = ({ title, selected, onClick }) => {
  return (
    <Box
      onClick={onClick}
      display="flex"
      padding="6px 12px"
      flex={1}
      justifyContent="center"
      alignItems="center"
      gap="10px"
      borderRadius="10px"
      background={selected ? '#FFF' : 'transparent'}
      boxShadow={selected ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
    >
      <Text
        onClick={onClick}
        fw={500}
        fs={'14px'}
        lineHeight={'24px'}
        fontStyle={'normal'}
        color={selected ? 'var(--Text-Primary-default, #000)' : 'var(--Text-Primary-description, #909090)'}
      >
        {title}
      </Text>
    </Box>
  );
};

export default TimeTab;

import { Box } from '@chakra-ui/layout';
import { Image, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import DashBoardImage from '../assets/images/pngs/DashBoardImage.png';
import Loginbg from '../assets/images/pngs/Loginbg.png';
import Text from '../components/common/Text';
import LogInForm from '../components/logIn/loginForm/LoginForm';
function Login() {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  return (
    <>
      {isMobile ? (
        <LogInForm />
      ) : (
        <Box
          // height={[
          //   'calc(100vh - 48px)',
          //   'calc(100vh - 120px)',
          //   'calc(100vh - 120px)',
          //   'calc(100vh - 48px)',
          //   'calc(100vh - 48px)',
          // ]}
          display="flex"
          height={['100%', '100%', '932px', '932px', '100vh']}
          alignItems={['flex-end', 'flex-start']}
          background="var(--Neutrals-8, #353535)"

          // margin={['18px', '60px', '60px', '24px', '24px']}
        >
          <Box
            w={['370px', '450px', '500px']}
            bg="#353535"
            display={['none', 'none', 'block', 'block', 'block']}
            height={'100%'}
          >
            <LogInForm />
          </Box>
          <Box
            className="login-side-container"
            background={`url(${Loginbg})`}
            backgroundPosition="center"
            backgroundSize="cover"
            w="100%"
            p={['0px', '10px', '40px']}
            height={'100%'}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box m="auto" display="flex" alignItems="center" flexDirection={'column'}>
              <Text c="var(--Text-Secondary-default, #FFF)" fs="24px">
                Easily track, analyze, and optimize your <br /> crypto portfolio, all in one place.
              </Text>
              <Image mt="64px" src={DashBoardImage} alt="logo" width={['450px', '450px', '450px', '100%']} />
              <Box
                display={['block', 'block', 'none', 'none', 'none']}
                bg={['#353535', '#353535']}
                mt="24px"
                w={['100%', '100%', '70%']}
                borderRadius="20px"
              >
                <LogInForm />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
export default Login;

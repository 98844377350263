import { Box, Button, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileUploader from '../common/FileUploader';
import Text from '../common/Text';
import axios from '../../api/axios';

const Step2 = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
    setIsFileUploaded(true);
  };

  const handleSaveFile = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('csv_file', selectedFile);

    try {
      const response = await axios.post('/api/user/address-book/', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        title: 'Success',
        description: 'File uploaded successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      navigate('/step-3');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong while uploading file',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" width="100%" height="100vh" pt="100px" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        width={['100%', '100%', 'fit-content', '410px']}
        padding={['0px 12px', '0px 24px', '0px']}
        flexDirection="column"
        alignItems="center"
        gap="56px"
        borderRadius="10px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs="20px">Almost there!</Text>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
            You can complete this later, feel free to skip
          </Text>
          <Box
            width={'100%'}
            gap={'6px'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginTop={'24px'}
          >
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#4096FF"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px"
              color={'var(--Neutrals-1, #FFF)'}
            >
              1
            </Box>
            <Box width={'30%'} height={'6px'} background={'#4096FF'} flex={1}></Box>
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background={isFileUploaded ? '#4096FF' : '#f4f4f4'}
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px"
              color={isFileUploaded ? 'var(--Neutrals-1, #FFF)' : 'var(--Text-Primary-description, #909090)'}
              transition="all 0.2s ease-in-out"
            >
              2
            </Box>
            <Box width={'30%'} height={'6px'} background={'#f4f4f4'} flex={1} transition="all 0.2s ease-in-out"></Box>
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#f4f4f4"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              color={'var(--Text-Primary-description, #909090)'}
              lineHeight="36px"
            >
              3
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs="28px" lineHeight={'32px'}>
            Add your token holders
          </Text>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
            Import a CSV file with the known token holders
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="28px" alignSelf="stretch">
          <FileUploader onFileSelect={handleFileSelect} maxFileSize={50 * 1024 * 1024} />
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="16px" alignSelf="stretch">
            <Button
              onClick={handleSaveFile}
              display="flex"
              alignItems="center"
              gap="var(--Checkbox-marginXS, 8px)"
              borderRadius="16px"
              border="1px solid #2D8DFB"
              background="#2D8DFB"
              _hover={{ background: '#2D8DFB' }}
              color="var(--Button-colorTextLightSolid, #FFF)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              width={'100%'}
              lineHeight="22px"
              isDisabled={!isFileUploaded}
            >
              <span>Save File</span>
            </Button>
            <Button
              onClick={() => navigate('/step-3')}
              display="flex"
              padding="6px var(--Button-paddingContentHorizontal, 16px)"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              alignSelf="stretch"
              borderRadius="16px"
              border="1px solid rgba(0, 0, 0, 0.15)"
              color="rgba(0, 0, 0, 0.88)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="22px"
              background="var(--Button-colorBgContainer, #FFF)"
            >
              <span>Skip</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step2;

import { Box, Button, Image, Input, InputGroup, Radio, RadioGroup, Stack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import Arrow from '../../assets/images/svgs/Arrow.svg';
import CheckBox from '../../assets/images/svgs/CheckBox.svg';
import Plus from '../../assets/images/svgs/Plus.svg';
import Tick from '../../assets/images/svgs/Tick.svg';
import Text from '../common/Text';

interface WalletForm {
  id: number;
  isExpanded: boolean;
  addressType: string;
  walletName: string;
  address: string;
}

const Step3 = () => {
  const [wallets, setWallets] = useState<WalletForm[]>([
    {
      id: 1,
      isExpanded: true,
      addressType: 'sender',
      walletName: '',
      address: '',
    },
  ]);

  const toggleExpand = (id: number) => {
    setWallets(wallets.map((wallet) => (wallet.id === id ? { ...wallet, isExpanded: !wallet.isExpanded } : wallet)));
  };

  const updateWalletType = (id: number, type: string) => {
    setWallets(wallets.map((wallet) => (wallet.id === id ? { ...wallet, addressType: type } : wallet)));
  };

  const updateWalletDetails = (id: number, field: keyof WalletForm, value: string) => {
    setWallets(wallets.map((wallet) => (wallet.id === id ? { ...wallet, [field]: value } : wallet)));
  };

  const handleAddWallet = (id: number) => {
    const wallet = wallets.find((w) => w.id === id);
    if (wallet?.walletName && wallet?.address) {
      setWallets(wallets.map((w) => (w.id === id ? { ...w, isExpanded: false } : w)));
    }
  };

  const toast = useToast();
  const navigate = useNavigate();

  const addNewWalletForm = () => {
    const newId = Math.max(...wallets.map((w) => w.id)) + 1;
    setWallets([
      ...wallets,
      {
        id: newId,
        isExpanded: true,
        addressType: 'sender',
        walletName: '',
        address: '',
      },
    ]);
  };

  const handleAddWalletAddress = async (id: number) => {
    const wallet = wallets.find((w) => w.id === id);
    if (wallet?.walletName && wallet?.address) {
      const inOutFlowData = {
        title: wallet.walletName,
        address: wallet.address,
        is_receiver: wallet.addressType === 'receiver',
      };

      try {
        await axios.put('/api/user/settings/inflow-outflow-addresses/', inOutFlowData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        setWallets(wallets.map((w) => (w.id === id ? { ...w, isExpanded: false } : w)));
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to add wallet address',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleSubmitAndLogin = async () => {
    try {
      const response = await axios.post(
        '/api/user/update-inflow-outflow-addresses/',
        {
          chain_id: 1,
          address_ids: wallets.map((wallet) => wallet.id), // Add address_ids
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Inflow outflow addresses have been updated',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };

  // const handleSubmitAndLogin = async () => {
  //   for (const wallet of wallets) {
  //     if (wallet.walletName && wallet.address) {
  //       const inOutFlowData = {
  //         title: wallet.walletName,
  //         address: wallet.address,
  //         is_receiver: wallet.addressType === 'receiver',
  //       };

  //       try {
  //         await axios.put('/api/user/settings/inflow-outflow-addresses/', inOutFlowData, {
  //           headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
  //         });
  //       } catch (error) {
  //         toast({
  //           title: 'Error',
  //           description: 'Failed to add wallet address',
  //           status: 'error',
  //           duration: 9000,
  //           isClosable: true,
  //         });
  //         return;
  //       }
  //     }
  //   }

  //   try {
  //     const response = await axios.post(
  //       '/api/user/update-inflow-outflow-addresses/',
  //       {},
  //       {
  //         headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
  //       },
  //     );

  //     if (response.status === 200) {
  //       toast({
  //         title: 'Success',
  //         description: 'Inflow outflow addresses have been updated',
  //         status: 'success',
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //       navigate('/');
  //     }
  //   } catch (error) {
  //     navigate('/');
  //   }
  // };

  return (
    <Box display="flex" width="100%" height="100vh" pt="100px" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        padding={['0px 12px', '0px 24px', '0px']}
        width={['100%', '100%', 'fit-content', '410px']}
        flexDirection="column"
        alignItems="center"
        gap="56px"
        borderRadius="10px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs="20px">One last thing</Text>
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
            You can complete this later, feel free to skip
          </Text>
          <Box
            width={'100%'}
            gap={'6px'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginTop={'24px'}
          >
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#4096FF"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px"
              color={'var(--Neutrals-1, #FFF)'}
            >
              1
            </Box>
            <Box width={'30%'} height={'6px'} background={'#4096FF'} flex={1}></Box>
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background={'#4096FF'}
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px"
              color={'var(--Neutrals-1, #FFF)'}
              transition="all 0.2s ease-in-out"
            >
              2
            </Box>
            <Box width={'30%'} height={'6px'} background={'#4096FF'} flex={1} transition="all 0.2s ease-in-out"></Box>
            <Box
              width="36px"
              height={'36px'}
              borderRadius={'50px'}
              background="#4096FF"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              color={'var(--Neutrals-1, #FFF)'}
              lineHeight="36px"
            >
              3
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          minWidth="310px"
          flexDirection="column"
          alignItems="flex-start"
          gap="36px"
          alignSelf="stretch"
          borderRadius="16px"
        >
          <Text fs="28px" lineHeight={'32px'}>
            Add your inflow and outflow addresses
          </Text>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="28px"
            alignSelf="stretch"
            padding={['0px', '0px', '16px']}
            borderRadius="8px"
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="24px" alignSelf="stretch">
              {wallets.map((wallet) => (
                <Box
                  key={wallet.id}
                  display="flex"
                  padding={wallet.isExpanded ? '24px' : '12px'}
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="16px"
                  alignSelf="stretch"
                  borderRadius={wallet.isExpanded ? '36px' : '16px'}
                  border="1px solid rgba(255, 255, 255, 0.10)"
                  background={wallet.isExpanded ? 'var(--Neutrals-1 #FFF)' : ' var(--Neutrals-2, #F6F6F6)'}
                  boxShadow={wallet.isExpanded ? '0px 1px 7px 0px rgba(0, 0, 0, 0.08)' : 'none'}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    alignSelf="stretch"
                    onClick={() => toggleExpand(wallet.id)}
                    cursor={'pointer'}
                  >
                    <Box display="flex" alignItems="center" gap="var(--Checkbox-marginXS, 8px)">
                      {!wallet.isExpanded && <Image src={Tick} alt="Tick" />}
                      <Text
                        fs={wallet.isExpanded ? '18x' : '14px'}
                        fw={500}
                        lineHeight={'22px'}
                        c="rgba(0, 0, 0, 0.88)"
                      >
                        {wallet.walletName || 'Add a wallet'}
                      </Text>
                    </Box>
                    <Box
                      transform={wallet.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'}
                      transition="transform 0.2s ease-in-out"
                    >
                      <Image src={Arrow} alt="Arrow" />
                    </Box>
                  </Box>

                  {wallet.isExpanded && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      gap="20px"
                      alignSelf="stretch"
                      borderRadius="8px"
                    >
                      <InputGroup
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                        alignSelf="stretch"
                      >
                        <Text
                          c="var(--Neutrals-500, #8A8A8A)"
                          fs="14px"
                          lineHeight={'16px'}
                          fw={400}
                          textAlign={'left'}
                        >
                          Wallet name
                        </Text>
                        <Input
                          placeholder="Enter Wallet name"
                          value={wallet.walletName}
                          _placeholder={{
                            color: 'var(--Neutrals-300, #C2C2C2)',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '20px',
                          }}
                          onChange={(e) => updateWalletDetails(wallet.id, 'walletName', e.target.value)}
                          display="flex"
                          height="48px"
                          padding="8px 13px"
                          alignItems="center"
                          gap="10px"
                          alignSelf="stretch"
                          borderRadius="14px"
                          border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                          background="var(--Neutrals-100, #FAFAFA)"
                        />
                      </InputGroup>

                      <InputGroup
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                        alignSelf="stretch"
                      >
                        <Text
                          c="var(--Neutrals-500, #8A8A8A)"
                          fs="14px"
                          lineHeight={'16px'}
                          fw={400}
                          textAlign={'left'}
                        >
                          Address
                        </Text>
                        <Input
                          fontFamily={'Poppins'}
                          placeholder="Enter Wallet address"
                          value={wallet.address}
                          onChange={(e) => updateWalletDetails(wallet.id, 'address', e.target.value)}
                          display="flex"
                          _placeholder={{
                            color: 'var(--Neutrals-300, #C2C2C2)',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '20px',
                          }}
                          height="48px"
                          padding="8px 13px"
                          alignItems="center"
                          gap="10px"
                          alignSelf="stretch"
                          borderRadius="14px"
                          border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                          background="var(--Neutrals-100, #FAFAFA)"
                        />
                      </InputGroup>

                      <InputGroup
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                        alignSelf="stretch"
                      >
                        <Text
                          c="var(--Neutrals-500, #8A8A8A)"
                          fs="14px"
                          lineHeight={'16px'}
                          fw={400}
                          textAlign={'left'}
                        >
                          Type
                        </Text>
                        <RadioGroup
                          onChange={(value) => updateWalletType(wallet.id, value)}
                          value={wallet.addressType}
                          display="flex"
                          gap="24px"
                        >
                          <Stack direction="row" spacing={4}>
                            <Box flexDirection="row" gap="8px" display="flex" alignItems="center">
                              {wallet.addressType === 'sender' ? (
                                <Image src={CheckBox} alt="Selected" />
                              ) : (
                                <Radio value="sender" borderColor="var(--Neutrals-200, #DEDEDE)" />
                              )}
                              <Text fs="14px" lineHeight="22px" fw={500}>
                                Sender (default)
                              </Text>
                            </Box>

                            <Box flexDirection="row" gap="8px" display="flex" alignItems="center">
                              {wallet.addressType === 'receiver' ? (
                                <Image src={CheckBox} alt="Selected" />
                              ) : (
                                <Radio value="receiver" borderColor="var(--Neutrals-200, #DEDEDE)" />
                              )}
                              <Text fs="14px" lineHeight="22px" fw={500}>
                                Receiver
                              </Text>
                            </Box>
                          </Stack>
                        </RadioGroup>
                      </InputGroup>

                      <Button
                        onClick={() => handleAddWalletAddress(wallet.id)}
                        display="flex"
                        alignItems="center"
                        gap="var(--Checkbox-marginXS, 8px)"
                        borderRadius="16px"
                        border="1px solid #2D8DFB"
                        background="#2D8DFB"
                        _hover={{ background: '#2D8DFB' }}
                        color="var(--Button-colorTextLightSolid, #FFF)"
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="500"
                        width={'100%'}
                        lineHeight="22px"
                      >
                        Add Wallet
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}

              <Button
                onClick={addNewWalletForm}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="var(--Button-marginXS, 8px)"
                padding="6px var(--Button-paddingContentHorizontal, 16px)"
                flexDirection="row"
                borderRadius="16px"
                border="1px dashed rgba(0, 0, 0, 0.15)"
                background="var(--Button-colorBgContainer, #FFF)"
                alignSelf="stretch"
              >
                <Image src={Plus} alt="Plus" />
                <Text fs="14px" fw={500} lineHeight={'22px'}>
                  Add another wallet{' '}
                </Text>
              </Button>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="16px" alignSelf="stretch">
              <Button
                onClick={handleSubmitAndLogin}
                display="flex"
                alignItems="center"
                gap="var(--Checkbox-marginXS, 8px)"
                borderRadius="16px"
                border="1px solid #2D8DFB"
                background="#2D8DFB"
                _hover={{ background: '#2D8DFB' }}
                color="var(--Button-colorTextLightSolid, #FFF)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                width={'100%'}
                lineHeight="22px"
              >
                Submit & Login
              </Button>
              <Button
                onClick={() => navigate('/')}
                display="flex"
                padding="6px var(--Button-paddingContentHorizontal, 16px)"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="8px"
                alignSelf="stretch"
                borderRadius="16px"
                border="1px solid rgba(0, 0, 0, 0.15)"
                color="rgba(0, 0, 0, 0.88)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                background="var(--Button-colorBgContainer, #FFF)"
              >
                Skip
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;

import { Input, useBreakpointValue } from '@chakra-ui/react';
import { Button, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Cross from '../../assets/images/svgs/CrossIcon.svg';
import { FlowAddress, TokenHolder } from '../../types/Types';
import AppModal from './AppModal';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (e: boolean) => void;
  editingRecord: TokenHolder | FlowAddress | null;
  onSaveChanges: (updatedRecord: any) => void;
  isAddingNew: boolean;
  type?: 'flow' | 'token';
}

const AddressBookEditModal: React.FC<IProps> = ({
  isShowModal,
  setIsShowModal,
  editingRecord,
  onSaveChanges,
  isAddingNew,
  type = 'token',
}) => {
  const [formData, setFormData] = useState<any>({
    wallet_address: '',
    name: '',
  });

  useEffect(() => {
    if (editingRecord) {
      if (type === 'flow') {
        setFormData({
          id: editingRecord.id,
          title: (editingRecord as FlowAddress).title,
          address: (editingRecord as FlowAddress).address,
          is_receiver: (editingRecord as FlowAddress).is_receiver,
        });
      } else {
        const tokenRecord = editingRecord as TokenHolder;
        setFormData({
          id: tokenRecord.id,
          wallet_address: tokenRecord.wallet_address,
          name: tokenRecord.name,
          address: tokenRecord.address,
          balance: tokenRecord.balance,
          contract_decimals: tokenRecord.contract_decimals,
          labels: tokenRecord.labels,
          created_at: tokenRecord.created_at,
          updated_at: tokenRecord.updated_at,
        });
      }
    } else {
      if (type === 'flow') {
        setFormData({
          title: '',
          address: '',
          is_receiver: true,
        });
      } else {
        setFormData({
          wallet_address: '',
          name: '',
        });
      }
    }
  }, [editingRecord, type]);

  const getInputFields = () => {
    if (type === 'flow') {
      return [
        {
          label: 'Name',
          key: 'title',
          placeholder: 'Enter name',
        },
        {
          label: 'Address',
          key: 'address',
          placeholder: '0x545445455445',
        },
      ];
    }
    return [
      {
        label: 'Name',
        key: 'name',
        placeholder: 'Enter name',
      },
      {
        label: 'Wallet Address',
        key: 'wallet_address',
        placeholder: '0x545445455445',
      },
    ];
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleSaveChanges = () => {
    onSaveChanges(formData);
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    md2: false,
  });

  return (
    <>
      {isMobile ? (
        <AppModal
          open={isShowModal}
          onCancel={() => setIsShowModal(false)}
          onClose={() => setIsShowModal(false)}
          width={'100%'}
          styles={{
            content: {
              display: 'flex',
              width: '100%',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
              borderRadius: '36px',
              border: '1px solid rgba(255, 255, 255, 0.10)',
              background: 'var(--Neutrals-1, #FFF)',
              boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.08)',
            },
            body: { width: '100%', height: '100%', gap: '12px' },
          }}
        >
          <Row
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Row
              style={{
                display: 'flex',
                paddingLeft: '24px',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                width: '100%',
              }}
            >
              <div style={{ width: '24px', height: '1px' }}></div>
              <span
                style={{
                  color: 'var(--Text-Black, #000)',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '28px',
                }}
              >
                {isAddingNew ? 'Add new address' : 'Edit your entry'}
              </span>
              <div style={{ cursor: 'pointer' }} onClick={() => setIsShowModal(false)}>
                <img src={Cross} alt="Close" />
              </div>
            </Row>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
                width: '100%',
              }}
            >
              {getInputFields().map((field) => (
                <div
                  key={field.key}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    alignSelf: 'stretch',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--Text-Primary-description, #909090)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                    }}
                  >
                    {field.label}
                  </span>
                  <Input
                    type="text"
                    placeholder={field.placeholder}
                    value={typeof formData[field.key] === 'boolean' ? '' : formData[field.key]?.toString() || ''}
                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                    style={{
                      color: 'var(--Neutrals-6, #909090)',
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      padding: '10px 12px',
                      gap: '10px',
                      lineHeight: '24px',
                      border: '0.5px solid var(--Neutrals-4, #E9E9E9)',
                      outline: 'none',
                      background: 'var(--Neutrals-2, #F6F6F6)',
                      flex: 1,
                      borderRadius: '16px',
                      width: '100%',
                    }}
                    fontFamily={'Poppins'}
                    _placeholder={{
                      color: 'var(--Neutrals-300, #C2C2C2)',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                    }}
                  />
                </div>
              ))}

              {type === 'flow' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    alignSelf: 'stretch',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--Text-Primary-description, #909090)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                    }}
                  >
                    Type
                  </span>
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        checked={formData.is_receiver === true}
                        onChange={() => setFormData((prev: any) => ({ ...prev, is_receiver: true }))}
                        style={{ cursor: 'pointer' }}
                      />
                      <span style={{ fontFamily: 'Poppins', fontSize: '14px' }}>Receiver</span>
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        checked={formData.is_receiver === false}
                        onChange={() => setFormData((prev: any) => ({ ...prev, is_receiver: false }))}
                        style={{ cursor: 'pointer' }}
                      />
                      <span style={{ fontFamily: 'Poppins', fontSize: '14px' }}>Sender</span>
                    </label>
                  </div>
                </div>
              )}

              <Button
                onClick={handleSaveChanges}
                style={{
                  height: '48px',
                  display: 'flex',
                  padding: '6px var(--Button-paddingContentHorizontal, 16px)',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  borderRadius: '16px',
                  border: '1px solid #2D8DFB',
                  background: '#2D8DFB',
                  color: 'var(--Button-colorTextLightSolid, #FFF)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '22px',
                  width: '100%',

                  marginTop: '12px',
                }}
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </AppModal>
      ) : (
        <AppModal
          open={isShowModal}
          onCancel={() => setIsShowModal(false)}
          onClose={() => setIsShowModal(false)}
          width={'fit-content'}
        >
          <Row
            style={{
              display: 'flex',
              width: '390px',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Row
              style={{
                display: 'flex',
                paddingLeft: '24px',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
              }}
            >
              <div style={{ width: '24px', height: '1px' }}></div>
              <span
                style={{
                  color: 'var(--Text-Black, #000)',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '28px',
                }}
              >
                {isAddingNew ? 'Add new address' : 'Edit your entry'}
              </span>
              <div style={{ cursor: 'pointer' }} onClick={() => setIsShowModal(false)}>
                <img src={Cross} alt="Close" />
              </div>
            </Row>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
                width: '100%',
              }}
            >
              {getInputFields().map((field) => (
                <div
                  key={field.key}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    alignSelf: 'stretch',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--Text-Primary-description, #909090)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                    }}
                  >
                    {field.label}
                  </span>
                  <Input
                    type="text"
                    placeholder={field.placeholder}
                    value={typeof formData[field.key] === 'boolean' ? '' : formData[field.key]?.toString() || ''}
                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                    style={{
                      color: 'var(--Neutrals-6, #909090)',
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      padding: '10px 12px',
                      gap: '10px',
                      lineHeight: '24px',
                      border: '0.5px solid var(--Neutrals-4, #E9E9E9)',
                      outline: 'none',
                      background: 'var(--Neutrals-2, #F6F6F6)',
                      flex: 1,
                      borderRadius: '16px',
                      width: '100%',
                    }}
                    _placeholder={{
                      color: 'var(--Neutrals-300, #C2C2C2)',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                    }}
                  />
                </div>
              ))}

              {type === 'flow' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    alignSelf: 'stretch',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--Text-Primary-description, #909090)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                    }}
                  >
                    Type
                  </span>
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        checked={formData.is_receiver === true}
                        onChange={() => setFormData((prev: any) => ({ ...prev, is_receiver: true }))}
                        style={{ cursor: 'pointer' }}
                      />
                      <span style={{ fontFamily: 'Poppins', fontSize: '14px' }}>Receiver</span>
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        checked={formData.is_receiver === false}
                        onChange={() => setFormData((prev: any) => ({ ...prev, is_receiver: false }))}
                        style={{ cursor: 'pointer' }}
                      />
                      <span style={{ fontFamily: 'Poppins', fontSize: '14px' }}>Sender</span>
                    </label>
                  </div>
                </div>
              )}

              <Button
                onClick={handleSaveChanges}
                style={{
                  height: '48px',
                  display: 'flex',
                  padding: '6px var(--Button-paddingContentHorizontal, 16px)',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  borderRadius: '16px',
                  border: '1px solid #2D8DFB',
                  background: '#2D8DFB',
                  color: 'var(--Button-colorTextLightSolid, #FFF)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '22px',
                  width: '100%',
                  marginTop: '12px',
                }}
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </AppModal>
      )}
    </>
  );
};

export default AddressBookEditModal;

import { Box, Button, FormControl, Image, Input, InputGroup, useBreakpointValue, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import CoveyBlack from '../../../assets/images/svgs/CoveyBlack.svg';
import CoveyLogoText from '../../../assets/images/svgs/CoveyText.svg';
import { Check } from '../../../Icons/Check';
import { useDashboardStore, useLoginStore } from '../../../store';
import Text from '../../common/Text';
import { PasswordField } from './PasswordField';

function LogInForm() {
  const toast = useToast();
  const navigate = useNavigate();
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const setReplacedDashboard = useDashboardStore((state: any) => state.setReplacedDashboard);

  const handleLogin = () => {
    const userData = {
      email: username,
      password: password,
      remember: rememberMe, // Add remember me to the request if needed
    };

    axios
      .post('/api/user/login/', userData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.data, 'response.data.data');
          localStorage.setItem('access_token', response.data.data.access_token);
          localStorage.setItem('refresh_token', response.data.data.refresh_token);
          setLoginDetails(response.data.data);
          setReplacedDashboard(false);
        }
      })
      .catch(() => {
        toast({
          title: 'Login error',
          description: 'Something went wrong while logging in',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  return (
    <Box
      display="flex"
      height={'100%'}
      padding={['100px 12px 0px 12px', '100px 12px 0px 12px', '100px 12px 0px 12px', '56px 36px']}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      background="var(--Neutrals-1, #FFF)"
      gap={['36px', '36px', '36px', '0px']}
    >
      {/* Logo Section */}
      <Box display={'flex'} gap="10px" justifyContent={['center', 'center', 'center', 'flex-start']} width={'100%'}>
        <Image src={CoveyBlack} width={'38.276px'} height={'31.406px'} />
        <Image src={CoveyLogoText} />
      </Box>

      <Box
        display="flex"
        minWidth="310px"
        flexDirection="column"
        alignItems="flex-start"
        gap="36px"
        alignSelf="stretch"
        borderRadius="16px"
        padding={['0px', '0px 24px', '0px']}
      >
        {/* Welcome Text */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems={['center', 'center', 'center', 'flex-start']}
          gap="8px"
          alignSelf="stretch"
        >
          <Text fs="28px" fontWeight={600} lineHeight={'32px'}>
            Welcome back
          </Text>
          <Text
            fs="14px"
            fontWeight={400}
            textAlign={'left'}
            lineHeight={'22px'}
            c="var(--Text-Primary-description, #909090)"
          >
            Enter your credentials to access your account
          </Text>
        </Box>

        <Box
          display="flex"
          minWidth="310px"
          flexDirection="column"
          alignItems="flex-start"
          gap="36px"
          alignSelf="stretch"
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="24px" alignSelf="stretch">
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="12px" alignSelf="stretch">
              <FormControl display="flex" flexDirection="column" alignItems="flex-start" gap="20px" alignSelf="stretch">
                {/* Username Input */}
                <InputGroup display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
                  <Text c="var(--Neutrals-500, #8A8A8A) " fs="14px" lineHeight={'16px'} fw={400} textAlign={'left'}>
                    Username
                  </Text>
                  <Input
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    display="flex"
                    height="48px"
                    padding="8px 13px"
                    _placeholder={{
                      color: 'var(--Neutrals-300, #C2C2C2)',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                    }}
                    alignItems="center"
                    gap="10px"
                    fontFamily={'Poppins'}
                    alignSelf="stretch"
                    borderRadius="14px"
                    border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                    background="var(--Neutrals-100, #FAFAFA);"
                  />
                </InputGroup>

                {/* Password Field */}
                <PasswordField password={password} setPassword={setPassword} />
              </FormControl>
              <Text
                onClick={() => navigate('/passstep-1')}
                c="var(--Text-action, #4096FF)"
                fs="14px"
                lineHeight={'22px'}
                fw={500}
                cursor="pointer"
              >
                Forgot password?
              </Text>
            </Box>

            {/* Remember Me Checkbox */}
            <Box
              display="flex"
              alignItems="center"
              gap="var(--Checkbox-marginXS, 8px)"
              onClick={toggleRememberMe}
              cursor="pointer"
            >
              <Check isSelected={rememberMe} />
              <Text c="rgba(0, 0, 0, 0.88)" fs="14px" fw={500} lineHeight={'22px'}>
                Remember me
              </Text>
            </Box>

            {/* Login Button */}
            <Button
              display="flex"
              alignItems="center"
              gap="var(--Checkbox-marginXS, 8px)"
              borderRadius="16px"
              border="1px solid #2D8DFB"
              background="#2D8DFB"
              _hover={{ background: '#2D8DFB' }}
              color="var(--Button-colorTextLightSolid, #FFF)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              width={'100%'}
              lineHeight="22px"
              onClick={handleLogin}
            >
              <span>Login</span>
            </Button>
          </Box>
        </Box>

        {/* Sign Up Link */}
        <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight={'22px'}>
          Don&apos;t have an account?{' '}
          <span
            onClick={() => navigate('/sign-up')}
            style={{ color: 'var(--Text-action, #4096FF)', cursor: 'pointer' }}
          >
            Sign up
          </span>
        </Text>
      </Box>
      {isMobile ? (
        <div></div>
      ) : (
        <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={400} lineHeight={'16px'}>
          Copyright © Covey Teach, 2024.
        </Text>
      )}
    </Box>
  );
}

export default LogInForm;
